@import "../abstracts/_mixins.scss";
@import "../abstracts/_variables.scss";

// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/**
 * Basic styles for links
 */
a {
  color: black;
  text-decoration: none;

  @include on-event {
    color: $text-color;
    text-decoration: underline;
  }
}

/**
* Material UI Modal styling
*/

div[role="dialog"] {
  z-index: 1700 !important;
  max-width: 900px; // Added to make terms and conditions pop-up text size better
 }

.teamAccessDialogImage{
  z-index: 1700 !important;
  
  @media screen and (max-width: 389px) {
    img{
      width: 0%;
    }
  };
    @media screen and (max-height: 721px) {
    img{
      width: 0%;
    }
  };
}