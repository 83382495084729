// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
.p-home__page {
  position: absolute;
  width: 100%;
  min-height: 100vh;
  background: url("/public/images/sff_landing_bg.svg") center center no-repeat;
  background-size: cover;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(#f4f4f4, #f4f4f4);
    opacity: 0.04;
  }
}

.p-home__container {
  margin: 0;
}

.p-home__content {
  font-family: "Graphik-Semibold", "Roboto", sans-serif;
  width: 100%;
 // margin-top: 3.25em; Removed so dashboard is not pushed down
}
