.c-share-price-card {
  &__container {
    display: flex;
    justify-content: space-between;
    background-color: white;
    width: 25em;
    border-radius: 0.25em;
    padding: 2em;
    box-shadow: 0 0.15em 0.8em 0 rgba(0, 0, 0, 0.16);
    position: relative;
    margin: 1.5em 2em 1em 5em;
    z-index: 1;
  }

  &__alternative-container {
    position: relative;
    margin: 1.5em 2em 1em 5em;
    z-index: 1;
  }

  &__tile-shareprice-rightdiv {
    display: flex;
    align-items: flex-start;
  }

  &__tile-shareprice-leftdiv {
    display: flex;
    flex-direction: column;
  }

  &__tile-shareprice-price {
    height: 43px;
    font-size: 42px;
    font-weight: 500;
    letter-spacing: 2.65px;
    font-family: "Graphik-Medium";
  }

  &__tile-shareprice-updatedate {
    display: block;
    height: 16px;
    font-family: Graphik;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: left;
    color: #333333;
  }

  &__tile-shareprice-icon {
    margin-right: 12px;
  }

  &__button-container {
    width: 25em;
    margin: -2em 2em 2em 5em;
    padding-top: 1em;
    background-color: rgb(65, 122, 93);
    border-radius: 0.3em;
    box-shadow: 0 0.2em 0.5em 0.05em rgba(0, 0, 0, 0.16);
    z-index: 0;
  }

  &__tile-shareprice-icon {
    color: #427b5d;
  }

  &-icon {
    margin-left: 1em;
    padding-bottom: 0.2em;
  }

  &-iconflat {
    width: 0.5em !important;
    padding-bottom: 0.5em;
    font-size: 1.3em;
  }
}
@media all and (max-width: 1024px) {
  .c-share-price-card {
    &__container,
    &__alternative-container {
      margin-left: 2em;
    }
  }
  .c-share-price-card__button-container {
    margin-left: 2em;
  }
}
