.c-farm-switcher__container {
  display: flex;
  align-items: center;
  min-height: 2em;
  text-align: right;

  .farm-name {
    font-family: 'Graphik-SemiBold'; // Added this here to get the farm-name to override wrong mui font
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .farm-id {
    font-family: 'Graphik';
  }

  .c-farm-switcher__form-control {
    vertical-align: middle;
    margin-right: 1.25em;
    display: inline-flex;
    max-width: 25em;
    text-align: right;
    text-overflow: ellipsis;
    right: 0;
    padding: 0.85em 0;

    div:before,
    div:after,
    div:hover {
      border-bottom: none !important;
    }

    #select-selectedFarmId {
      font-size: 0.9em;

      &:focus {
        background-color: transparent !important;
      }

      .farm-name {
        font-family: "Graphik-SemiBold";
        overflow: hidden;
        text-overflow: ellipsis;
        width: 15em;

        &.defocus {
          color: #333;
        }
      }

      .farm-id {
        font-family: "Graphik";
        color: #9b9b9b;

        &.defocus {
          color: #9b9b9b;
        }
      }
    }
  }

  .c-header__farm-switcher-container {
    border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
  }

  .c-farm-switcher__all-farms-container {
    display: inline-block;
    line-height: 1.5em;
    font-size: 1em;

    &.show {
      animation: allFarmSelectedShow 0.25s forwards;
    }

    &.hide {
      animation: allFarmSelectedHide 0.25s forwards 0.25s;
    }

    .c-farm-switcher__all-farms-button {
      background: #fff;
      text-transform: uppercase;
      color: #bababa;
      border-left: 1px solid #f4f4f4;
      border-right: 1px solid #f4f4f4;
      font-size: 0.6em;
      font-family: "Graphik-SemiBold";
      letter-spacing: 0.1em;
      vertical-align: middle;
      text-align: center;
      padding: 1.46em 1.4em;
      cursor: pointer;
      white-space: nowrap;

      &.selected {
        animation: allFarmSelectedFadeIn 0.25s forwards;
      }

      &.unselected {
        animation: allFarmSelectedFadeOut 0.25s forwards;
      }
    }
  }
}

.c-farm-switcher__progress-container {
  margin: 1.4em 1em;

  .c-farm-switcher__progress {
    color: #427b5d !important;
  }
}

#menu-selectedFarmId {
  z-index: 1600;

  li {
    text-align: right;
    height: auto;
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 0.75em;

    &:hover {
      background-color: rgba(66, 123, 93, 0.07) !important;
    }

    &:focus {
      border: 4px rgb(66, 123, 93) solid;
      border-width: 0px 0px 0px 4px;
      padding-left: 16px;
      background-color: rgba(66, 123, 93, 0.07) !important;
    }
  }
  .subcategory-name {
    font-family: "Graphik-SemiBold";
    text-align: center;
    color: #427b5d;
    position: relative;
    min-width: 350px;
  }
  .subcategory-name:hover{
    background-color: rgba(255, 255, 255, 0.07) !important;
  }
  .subcategory-name:before{
    content:" ";
    height: 2px;
    width: calc(50% - 80px);
    background: #ededed;
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
  } 
  .subcategory-name:after{
    content:" ";
    height: 2px;
    width: calc(50% - 80px);
    background: #ededed;
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
  }

  .farm-name {
    font-family: "Graphik-SemiBold";
    color: #8b8b8b;

    &.selected {
      color: rgba(0, 0, 0, 0.87);
    }
  }

  .farm-id {
    font-family: "Graphik";
    color: #9b9b9b;
  }

  .farm-item {
    width: 100%;
  }
}

@keyframes allFarmSelectedFadeIn {
  from {
    background: #fff;
    color: #bababa;
  }

  to {
    background: #427b5d;
    color: #fff;
  }
}

@keyframes allFarmSelectedFadeOut {
  from {
    background: #427b5d;
    color: #fff;
  }

  to {
    background: #fff;
    color: #bababa;
  }
}

@keyframes allFarmSelectedShow {
  from {
    font-size: 0;
    padding: 0;
    opacity: 0;
  }

  to {
    line-height: 1.5em;
    font-size: 1em;
    opacity: 1;
  }
}

@keyframes allFarmSelectedHide {
  from {
    line-height: 1.5em;
    font-size: 1em;
    opacity: 1;
  }

  99% {
    font-size: 0.25em;
  }

  to {
    font-size: 0;
    padding: 0;
    opacity: 0;
  }
}
