.c-login-button__progress {
  margin: 0 auto;
  color: #427b5d !important;
}

.p-login__b2c-button-container {
  button {
    &:hover {
      background: #fff radial-gradient(circle, transparent 1%, #fff 1%) center/15000%;
    }

    &:active {
      background-color: #c6c6c6;
      background-size: 100%;
      transition: background 0s;
    }
  }
}
