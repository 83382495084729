.c-nzfap {
  &__container {
    padding: 1em;
    margin: 1em 5em 0em 5em;
    background-color: white;
    border-radius: 0.3em;
    box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.16);
    min-width: 540px;
    max-width: 675px;
    border-bottom-width: 20px;
    border-bottom-style: solid;

    &.red {
      border-bottom-color: rgb(224, 84, 74);
    }

    &.green {
      border-bottom-color: rgb(66, 123, 93);
    }

    &.gold {
      border-bottom-color: rgb(176, 181, 105);
    }

    &.grey {
      border-bottom-color: rgb(172, 172, 172);
    }
  }

  &__field {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
  }

  &__species {
    font-size: 1.4em;
    text-transform: uppercase;
    font-family: "Graphik-Semibold";
  }

  &__wrapper {
    vertical-align: middle;
  }

  &__label {
    font-family: "Graphik";
    color: rgb(155, 155, 155);
    font-size: 0.7em;
    text-align: right;
  }

  &__value {
    font-family: "Graphik-Medium";
    font-size: 0.9em;
  }

  &__status {
    font-size: 0.6em;
    text-transform: uppercase;
    position: relative;
    top: 1.8em;
    float: right;
    color: white;
    font-family: "Graphik-Semibold";
    letter-spacing: 0.1em;
  }

  &__empty-certificates-container {
    width: 100%;
    max-width: 40em;
    min-width: 31em;
    height: 72vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__empty-certificates-text {
    font-family: "Graphik";
    font-weight: 100;
    font-size: 1.1em;
    text-align: center;
    margin-top: 2em;
  }
}

@media all and (max-width: 1024px) {
  .c-nzfap {
    &__container {
      margin: 1em 5em 0em 2em;
    }
  }
}
