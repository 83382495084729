.c-search-switcher {
  &__button {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    width: 15em;
    font-size: 0.8em;
    font-family: "Graphik-Medium", "Roboto", "sans-serif";
    border-right: 0.05em solid rgb(240, 240, 240);
    cursor: pointer;
  }
}
