.c-schedule {
  &__container {
    display: inline-block;
    background: rgba(0, 0, 0, 0.02);
    width: 100%;
    height: 100vh;
    -ms-overflow-style: none;
    overflow: "-moz-scrollbars-none";
    padding-bottom: 5em;
  }

  &__header {
    font-size: 1.5em;
    margin: 1em auto 0.25em 3.3em;
    text-transform: uppercase;
  }

  &__canvasDiv {
    margin-left: 5em;
    height: 80vh;
    div {
      font-size: 0.8em;
      font-family: "Graphik", "Roboto", "sans-serif";
      color: rgb(155, 155, 155);
      padding: 0em 0em 1em 0em;
    }
  }

  &__text {
    width: 37em;
    padding-right: 3em;
  }

  &__canvas {
    height: 95%;
    cursor: pointer;
    overflow: hidden;
    box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
  }
}

@media all and (max-width: 1024px) {
  .c-schedule {
    &__header {
      font-size: 1.2em;
      margin: 1em auto 0.25em 1.75em;
    }

    &__canvasDiv {
      margin-left: 2em;
    }

    &__canvas {
      width: 55vh;
      min-height: 30em;
    }
  }
}
