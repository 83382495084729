// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

@font-face {
  font-family: "Graphik";
  src: url("/public/fonts/Graphik-Regular.eot"); /* IE9 Compat Modes */
  src: url("/public/fonts/Graphik-Regular.woff") format("woff"),
    /* Pretty Modern Browsers */ url("/public/fonts/Graphik-Regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/public/fonts/Graphik-Regular.svg#Graphik-Regular") format("svg"); /* Legacy iOS */
}
  
@font-face {
  font-family: "Graphik-Bold";
  src: url("/public/fonts/Graphik-Bold.eot"); /* IE9 Compat Modes */
  src: url("/public/fonts/Graphik-Bold.woff") format("woff"),
    /* Pretty Modern Browsers */ url("/public/fonts/Graphik-Bold.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("/public/fonts/Graphik-Bold.svg#Graphik-Bold")
      format("svg"); /* Legacy iOS */
}
  
@font-face {
  font-family: "Graphik-Light";
  src: url("/public/fonts/Graphik-Light.eot"); /* IE9 Compat Modes */
  src: url("/public/fonts/Graphik-Light.woff") format("woff"),
    /* Pretty Modern Browsers */ url("/public/fonts/Graphik-Light.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/public/fonts/Graphik-Light.svg#Graphik-Light") format("svg"); /* Legacy iOS */
}
  
@font-face {
  font-family: "Graphik-Medium";
  src: url("/public/fonts/Graphik-Medium.eot"); /* IE9 Compat Modes */
  src: url("/public/fonts/Graphik-Medium.woff") format("woff"),
    /* Pretty Modern Browsers */ url("/public/fonts/Graphik-Medium.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/public/fonts/Graphik-Medium.svg#Graphik-Medium") format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: "Graphik-RegularItalic";
  src: url("/public/fonts/Graphik-RegularItalic.eot"); /* IE9 Compat Modes */
  src: url("/public/fonts/Graphik-RegularItalic.woff") format("woff"),
    /* Pretty Modern Browsers */ url("/public/fonts/Graphik-RegularItalic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/public/fonts/Graphik-RegularItalic.svg#Graphik-RegularItalic")
      format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: "Graphik-Semibold";
  src: url("/public/fonts/Graphik-Semibold.eot"); /* IE9 Compat Modes */
  src: url("/public/fonts/Graphik-Semibold.woff") format("woff"),
    /* Pretty Modern Browsers */ url("/public/fonts/Graphik-Semibold.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/public/fonts/Graphik-Semibold.svg#Graphik-Semibold") format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: "ArcherSSm-Bold";
  src: url("/public/fonts/ArcherSSm-Bold.eot"); /* IE9 Compat Modes */
  src: url("/public/fonts/ArcherSSm-Bold.woff") format("woff"),
    /* Pretty Modern Browsers */ url("/public/fonts/ArcherSSm-Bold.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/public/fonts/ArcherSSm-Bold.svg#ArcherSSm-Bold") format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: "ArcherSSm-Light";
  src: url("/public/fonts/ArcherSSm-Light.eot"); /* IE9 Compat Modes */
  src: url("/public/fonts/ArcherSSm-Light.woff") format("woff"),
    /* Pretty Modern Browsers */ url("/public/fonts/ArcherSSm-Light.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/public/fonts/ArcherSSm-Light.svg#ArcherSSm-Light") format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: "ArcherSSm-Medium";
  src: url("/public/fonts/ArcherSSm-Medium.eot"); /* IE9 Compat Modes */
  src: url("/public/fonts/ArcherSSm-Medium.woff") format("woff"),
    /* Pretty Modern Browsers */ url("/public/fonts/ArcherSSm-Medium.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/public/fonts/ArcherSSm-Medium.svg#ArcherSSm-Medium") format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: "ArcherSSm-Semibold";
  src: url("/public/fonts/ArcherSSm-Semibold.eot"); /* IE9 Compat Modes */
  src: url("/public/fonts/ArcherSSm-Semibold.woff") format("woff"),
    /* Pretty Modern Browsers */ url("/public/fonts/ArcherSSm-Semibold.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/public/fonts/ArcherSSm-Semibold.svg#ArcherSSm-Semibold") format("svg"); /* Legacy iOS */
}