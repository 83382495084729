.c-payments__container {
  display: inline-block;
  width: 100%;
  height: calc(100vh - 8.69em);
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  overflow: "-moz-scrollbars-none";
  min-width: 768px;
  padding-bottom: 2.8em;

  &-tools {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 5em;
  }
}

.c-payments__load-error-container {
  height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.c-payments-payments-history__container {
  display: inline-block;
  width: 100%;
  height: 95vh;
}

.c-payments__header {
  font-size: 2em;
  margin-bottom: -0.5em;
}

.c-payments__header-container {
  display: block;
  margin-left: 5em;
  max-width: 45em;
}

.c-payments__container-divider {
  width: 1em;
  background: #fff;
  box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.13);
}

.c-payments__summary-header {
  padding: 0px 0px 0px 0px;
  border-bottom: 0px;
}

.c-payments__empty-stocks-container {
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.c-payments__empty-stocks-text {
  font-family: "Graphik";
  font-weight: 100;
  font-size: 1.1em;
  text-align: center;
  margin-top: 2em;
}

.c-paymentHistorySummaryLine__div {
  background-color: white;
  border-radius: 8px;
  min-width: 70vw;
  margin-left: 5em;
  margin-right: 5em;
  margin-bottom: 0.6em;

  > div {
    box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.13);

    .c-paymentHistorySummaryLine__item {
      &.grid-container {
        padding: 0.25em 0;

        > div {
          padding: 1em 0.25em;
        }

        > div:nth-child(6) {
          display: none;
        }

        > div:nth-child(7) {
          display: none;
        }
      }
    }
  }

  > .expanded {
    border-left: 7px solid #427b5d;

    #c-paymentHistorySummaryLine__panel-summary {
      > div {
        margin: 12px 0;

        .c-paymentHistorySummaryLine__item {
          &.grid-container {
            > div:nth-child(2) {
              margin-left: -0.11em;
            }

            > div:nth-child(3) {
              margin-left: -0.19em;
            }

            > div:nth-child(4) {
              margin-left: -0.2em;
            }

            > div:nth-child(5) {
              margin-left: -0.71em;
            }

            > div:nth-child(6) {
              display: block;
            }

            > div:nth-child(7) {
              display: block;
              padding: 0 2em 0 0;
            }
          }

          &.border-filler-container {
            max-width: 3.65em;
            padding: 0.5em;
          }

          &.bottom-border {
            border-bottom: 1px solid rgba(51, 51, 51, 0.06);
          }
        }
      }

      > :last-child {
        padding-right: 0em;
      }
    }

    .c-paymentHistorySummaryLine__item {
      &.icon {
        background-color: #427b5d;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        display: inline-block;
        color: white;
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
      }
    }

    > div:nth-of-type(1) {
      padding-left: 17px;
      padding-right: 0em;
    }

    .c-paymentHistoryDetailLine__div {
      padding-left: 8px;
    }
  }

  .c-paymentHistorySummaryLine__item {
    font-size: 1em;
    font-family: "Graphik", "Roboto", "sans-serif";

    &.icon-container {
      text-align: center;
      padding-left: 1em;
      max-width: 3.5em;
    }

    &.icon {
      color: #427b5d;
      border: 2px solid #427b5d;
      border-radius: 50%;
      height: 30px;
      width: 30px;
    }

    &.capitalise {
      text-transform: uppercase;
      white-space: nowrap;
    }

    &.right {
      text-align: right;
      font-family: "Graphik-Semibold", "Roboto", "sans-serif";
    }

    .c-paymentHistorySummaryLine__item-subheading {
      font-family: "Graphik-Medium", "Roboto", "sans-serif";
      font-size: 0.65em;
      color: #bababa;
      margin-top: 0.25em;
      letter-spacing: 0.05em;
      white-space: nowrap;
    }
  }
}

.c-paymentHistoryDetailLine__div {
  display: block !important;
  background-color: white;
  border-radius: 8px;
  min-width: 64vw;
  width: 100%;

  > div {
    padding: 0px;
  }

  > div {
    > div {
      > div {
        align-self: flex-start;
        padding: 0.25em 0 0.25em 0.3em !important;
      }
    }
  }

  .c-paymentHistoryPaymentLine {
    padding: 0.25em 0 0.25em 0em !important;

    .c-paymentHistoryDetailLine__item {
      font-size: 1em;

      &.capitalise {
        padding-left: 0em !important;
      }
    }
  }

  .c-paymentHistoryPaymentLine__header {
    .border-filler-container {
      max-width: 3.65em;

      + div {
        padding: 0 !important;
        max-width: 90.6666666%;
      }
    }
    .bottom-border {
      border-bottom: 1px solid rgba(51, 51, 51, 0.06);
      margin-bottom: 1.5em;
    }
  }

  .c-paymentHistoryDetailLine__item {
    font-size: 0.9em;
    font-family: "Graphik", "Roboto", "sans-serif";

    > svg {
      color: #427b5d;
      vertical-align: baseline;
    }

    > svg + span {
      color: #427b5d;
      position: relative;
      left: 0.7em;
      bottom: 0.3em;
    }

    &.filler-container {
      max-width: 3.5em;
    }

    &.capitalise {
      text-transform: uppercase;
    }

    &.documentType {
      font-family: "Graphik-Semibold", "Roboto", "sans-serif";
      color: white;
      text-align: center;
      font-size: 0.5em;
      padding: 0.3em 0.2em !important;
      width: 10em;

      &.invoice {
        background-color: rgb(66, 123, 93);
        position: relative;
        top: 0.75em;
      }

      &.journal {
        background-color: rgb(176, 181, 105);
        position: relative;
        top: 0.75em;
      }

      &.credit-memo {
        background-color: rgb(224, 84, 74);
        position: relative;
        top: 0.75em;
      }
    }

    &.icon {
      > svg {
        color: rgb(66, 123, 93);
      }
    }

    &.negative {
      color: rgb(224, 84, 74);
    }

    &.right {
      text-align: right;
    }

    &.download-pdf {
      font-size: 1em;
      width: fit-content;
      cursor: pointer;
    }

    &.no-download-pdf {
      > svg {
        color: #bababa;
      }
      > svg + span {
        font-size: 1.1em;
        color: #bababa;
      }
    }

    .c-paymentHistoryDetailLine__item-subheading {
      font-family: "Graphik-Medium", "Roboto", "sans-serif";
      font-size: 0.7em;
      color: #bababa;
      letter-spacing: 0.05em;
    }
  }

  .c-paymentHistoryDetailLine__item__invoice {
    background-color: rgb(66, 123, 93);

    > span {
      font-family: "Graphik-Semibold", "Roboto", "sans-serif";
      color: white;
      width: 10em;
    }
  }
}

.c-paymentHistoryDateSelector_div {
  margin-left: 5em;  
  min-width: 70vw;
  position: relative;

  .c-paymentHistoryDateSelector {
    width: 95%;
    flex-direction: row !important;
    justify-content: flex-end;

    > span {
      font-family: "Graphik", "Roboto", "sans-serif";
      color: #bababa;
      width: 7em;
      display: flex;
      align-items: center;
      margin-bottom: 1.5em;
    }

    .c-paymentHistoryDateSelector-select {
      margin-top: 0.7em;
      margin-bottom: 2em;
      font-family: "Graphik-Medium" !important;
      border-bottom: 0.25em solid #000;

      &::before {
        border-bottom: none !important;
      }

      &::after {
        border-bottom: none !important;
      }

      svg {
        display: none;
      }

      > span {
        font-size: 1.1em;
      }

      > div {
        > div {
          font-size: 1.1em;
          padding-right: 0;
          text-transform: uppercase;

          &:focus {
            background: transparent;
          }
        }
      }
    }

    .c-paymentHistoryDateSelector-daterange {
      margin-bottom: 1em;
      margin-left: 0.5em;
      color: black;

      &-disabled {
        color: #bababa;
      }
    }    
  }
}

.c-paymentHistoryDateSelector-menu {
  border-radius: 0 !important;

  :hover {
    cursor: pointer;
  }

  li {
    padding: 1em 1.5em;
    font-family: "Graphik", "Roboto", "sans-serif";
    font-size: 0.8em;
    line-height: 2;
    letter-spacing: 0.05em;
    border: 4px solid transparent;
    border-width: 0px 0px 0px 4px;

    &:hover {
      background-color: rgba(66, 123, 93, 0.07) !important;
    }

    &:focus {
      border: 4px rgb(66, 123, 93) solid;
      border-width: 0px 0px 0px 4px;
      background-color: rgba(66, 123, 93, 0.07) !important;
    }

    &.selected {
      font-weight: 800;
    }
  }
}

.c-paymentHistoryExport { 

  &-disablebutton {
    background-color: #cccccc !important;
    border-radius: 0 !important;
    margin-left: 1.5em !important;
    margin-top: 0.75em !important;;
    width: max-content;
    & span {
      font-family: "Graphik-Semibold";
      color: white;
      padding: 0px 25px;
    }
  }

  &-button {
    background-color: #417A5D !important;
    border-radius: 0 !important;
    margin-left: 1.5em !important;
    margin-top: 0.75em !important;;
    width: max-content;
    &:hover {
      background-color: #417a5ce5 !important;
      & span {
      color: white; 
      }
    }
    & span {
      font-family: "Graphik-Semibold";
      color: white;
      padding: 0px 25px;
    }
  }
  &-date-selector {
    min-width: 0;
    margin-left: 0;
    margin-right: 5em;
    
    .c-paymentHistoryDateSelector > span {
      width: 7em;
    }    
  }
  &-popover {   

    & div[role="document"] {
      padding: 1.5em 1.5em 1em 2em;
      right: 5.8em;
    }

    &.expanded {
      & div[role="document"] {
        left: calc(100% - 735px) !important;
      }
    }

    & h4 {
      font-family: "Graphik-Semibold", "Roboto", sans-serif;
      text-transform: uppercase;
      margin: 0.5em 0em;
    }
  }
  &-actions {
    display: flex;
    justify-content: flex-end;
  }

  &-close {
    background-color: 'white' !important;
    border-radius: 0 !important;
    &:hover {
        background-color: rgba(66, 123, 93, 0.07) !important;
    }
    & span {
        font-family: 'Graphik-Semibold';
        color: #427b5d;
        padding: 0px 22px;
    }
  }
}

/* Media queries - above 1440px wide */
@media all and (min-width: 1441px) {
  #c-paymentHistorySummaryLine__panel-summary {
    > div {
      .c-paymentHistorySummaryLine__item {
        &.grid-container {
          > div:nth-child(7) {
            max-width: 92.3333333%;
            flex-basis: 92.3333333%;
          }
        }
      }
    }
  }
}

@media all and (max-width: 1440px) {
  .c-paymentHistoryDetailLine__div {
    > .c-paymentHistoryDetailLine__div {
      > div {
        > div:nth-child(5) {
          max-width: 33.3333333%;
          flex-basis: 33.3333333%;
        }
        > div:nth-child(6) {
          display: none;
        }
      }
    }
  }
}

/* Media queries - Responsive timeline on screens between 961px and 1440px wide */
@media all and (min-width: 1281px) and (max-width: 1440px) {
  .c-paymentHistorySummaryLine__div {
    .c-paymentHistorySummaryLine__item {
      font-size: 1em;

      &.icon {
        height: 24px;
        width: 24px;
      }

      &.icon-container {
        padding-top: 1.25em;
      }
    }

    > .expanded {
      .c-paymentHistorySummaryLine__item {
        &.icon {
          height: 24px;
          width: 24px;
        }
      }
    }
  }

  .c-paymentHistoryDetailLine__div {
    .c-paymentHistoryDetailLine__item {
      font-size: 0.9em;

      &.filler-container {
        max-width: 3.5em;
      }

      &.collapsible-item-column {
        display: none;
      }
    }
  }
}

/* Media queries - Responsive timeline on screens between 1120px and 1280px wide */
@media all and (min-width: 1120px) and (max-width: 1280px) {
  .c-paymentHistorySummaryLine__div {
    .c-paymentHistorySummaryLine__item {
      font-size: 1em;

      &.icon {
        height: 24px;
        width: 24px;
      }

      &.icon-container {
        padding-top: 1.25em;
      }

      &.grid-container {
        > div:nth-child(5) {
          margin-left: -0.71em;
        }
      }
    }

    > .expanded {
      .c-paymentHistorySummaryLine__item {
        &.icon {
          height: 24px;
          width: 24px;
        }
      }
    }
  }

  .c-paymentHistoryDetailLine__div {
    .c-paymentHistoryDetailLine__item {
      font-size: 0.8em;

      &.filler-container {
        max-width: 3.5em;
      }

      &.collapsible-item-column {
        display: none;
      }
    }
  }
}

@media all and (max-width: 1280px) {
  .c-paymentHistoryDetailLine__div {
    .c-paymentHistoryDetailLine__item {
      &.no-download-pdf {
        > svg + span {
          font-size: 1.3em;
        }
      }
    }
  }
}

/* Media queries - Responsive timeline on screens up to 1024px wide */
@media all and (max-width: 1024px) {
  .p-payments__container {
    .p-payments__tabs {
      margin: 1em 0 1em 2em !important;
    }

    .c-paymentHistorySummaryLine__div {
      margin-left: 2em;
      margin-right: 2em;
    }

    .c-paymentHistoryDateSelector_div {
      margin-left: 2em !important;
      margin-right: 2em !important;
    }
  }
}

/* Media queries - Responsive timeline on screens less than 1119px wide */
@media all and (max-width: 1119px) {
  .c-payments__container {
    min-width: 720px;
  }

  .c-paymentHistorySummaryLine__div {
    .c-paymentHistorySummaryLine__item {
      font-size: 0.9em;

      &.icon {
        height: 24px;
        width: 24px;
      }

      &.icon-container {
        padding-top: 1.25em;
      }
    }

    > .expanded {
      .c-paymentHistorySummaryLine__item {
        &.icon {
          height: 24px;
          width: 24px;
        }
      }
    }
  }

  .c-paymentHistoryDetailLine__div {
    .c-paymentHistoryDetailLine__item {
      font-size: 0.75em;

      &.filler-container {
        max-width: 3.5em;
        margin-left: -0.25em;
      }

      &.collapsible-item-column {
        display: none;
      }
    }

    .c-paymentHistoryPaymentLine {
      .c-paymentHistoryDetailLine__item {
        font-size: 0.75em !important;
        &.capitalise {
          padding-left: 0.3em !important;
        }
      }
    }
  }
}

/* Media queries - Responsive timeline on screens more than 1920px wide */
@media all and (min-width: 1919px) {
  .c-paymentHistorySummaryLine__div {
    .c-paymentHistorySummaryLine__item {
      &.grid-container {
        > div:nth-child(5) {
          margin-left: 0 !important;
        }
      }
    }
  }
}
