.c-power-bi__container {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
}

.c-my-report__container {
  display: contents;
  background: rgba(0, 0, 0, 0.02);
  width: 100vw;
  padding-bottom: 5em;
  scrollbar-width: none;

  .c-my-report__tabs {
    display: block;
    margin-left: 5em;
    margin-right: 2em;

    [aria-selected="false"] {
      color: #ccc !important;
    }

    > div {
      > div {
        > span {
          height: 0.25em;
          background-color: #427b5d;
        }
      }
    }
  }
}

.c-my-account__container {
  .c-my-account__tabs {
    display: inline-flex;
    margin: 1em 2em 1em 5em;

    [aria-selected="false"] {
      color: #ccc !important;
    }

    .c-my-account__tab {
      font-family: "Graphik-SemiBold", "Roboto", sans-serif;
      font-size: 1.2em;
      padding: 0.5em 0;
      text-align: left;
      margin-right: 1em;
      min-width: auto;
      color: #000;

      > span {
        > span {
          padding: 0;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }

    > div {
        > span {
          height: 0.25em;
          background-color: #427b5d;
        }
      }
    }
  }


.c-my-powerbi-report__container {
  display: inline-block;
  background: rgba(0, 0, 0, 0.02);
  width: 100%;
  height: calc(100vh - 3.25em);
  padding-bottom: 1em;
  scrollbar-width: none;
  overflow: auto;

  .c-my-powerbi-report__tabs::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
  }

  .c-my-powerbi-report__tabs {
    display: inline-flex;
    margin: 1em 2em 0em 5em;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    [aria-selected="false"] {
      color: #ccc !important;
    }

    .c-my-powerbi-report__tab {

      font-family: "Graphik-SemiBold", "Roboto", sans-serif;
      color: #000;
      font-size: 1.2em;
      padding: 0.5em 0;
      text-align: left;
      margin-right: 1em;
      min-width: -webkit-fit-content;
      min-width: -moz-fit-content;
      min-width: fit-content;

      > span {
        > span {
          padding: 0;
        }
      }
    }

    .c-my-powerbi-report__tab-shrinkable {
      min-width: 0;
    }

    > div {
        > span {
          height: 0.25em;
          background-color: #427b5d;
        }
      }
    }
  }




.c-power-bi__Tab {
     //margin-left: 5.5em;
     height: 81vh;
          //height: 100vh;
     box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.16) !important;

  //@media all and (min-width: 1280px) and (max-width: 1599px) {
  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
    height: 83vh;
  }

  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2){
    height: 53vh;
  }

  @media only screen and (min-width: 1224px) and (orientation: landscape) {
    height: 85vh;
  }

  @media all and (max-width: 1279px) {
    height: 29vh;
  }

  @media all and (max-width: 1279px) and (orientation: landscape){
    height: 59vh;
  }

  @media all and (max-width: 959px) {
    height: 32vh;
    width: 70vw;
    margin-left: 3em;
    }
    @media screen and (orientation: portrait) {
    margin-left: 0em;
    }





  }

.c-power-bi__Contents {
  display: contents;
  
}

.c-power-bi__error {
  box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.16) !important;
  height: 80vh;
  min-height: 138px;
  display: grid;
  margin-left: 5em;
  margin-right: 5em;


  > div {
    padding: 1.8em;
    border-radius: 4px;
  }

  &-dashboard {
    height: calc(65vh - 8em);
  }

   &-link {
    font-size: 0.6em;
    font-family: "Graphik-Medium";
    color: #bababa;
    float: right;
    margin-top: 1.2em;
    margin-right: 1.5em;
  }

  &-header-1 {
    font-size: 1.3em;
  }

  /* Error styling */
  &.error {
    .c-load-error__container {
      margin-bottom: 0em;
      padding-top: 0.4em;
    }
    .c-load-error__message {
      font-size: 1em;
      text-align: center;
    }
    @media all and (min-width: 1280px) and (max-width: 1599px) {
      .c-load-error__message {
        font-size: 0.8em;
      }
    }
    @media all and (max-width: 1279px) {
      .c-load-error__container {
        padding-top: 1.4em;
      }
    }
    @media all and (max-width: 959px) {
      .c-load-error__message {
        font-size: 0.8em;
      }
    }
    .c-load-error__button {
      button {
        background: #fff;
        border: 1px solid #427b5d;
        border-radius: 0;

        span > svg,
        span > div {
          font-family: "Graphik-SemiBold";
          color: #427b5d;
        }
      }
    }
  }
}

.c-power-bi__analytics-container {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
}

.c-power-bi__Carcase-container {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.16) !important;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: inline-block;
  }

}

.c-power-bi__Carcase-graphShow {
  height: 71vh;
  width: 58vw;
  float: left;
  max-width: 100vw;
  max-height: 100vh;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: inline;
    width: 100%;
    height: 100%;
  }

  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5)
  {
    height: 44vh;
    width: 70vw;
    min-width: 50vw;
    min-height: 50vh;
  }

  @media only screen and (max-width: 1366px) and (max-height: 1024px) and (orientation: landscape)
  {
    min-width: 60vw;
    min-height: 80vh;
  }


@media only screen  and (min-width : 1224px) {
    height: 80vh;
}


@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : landscape)
and (-webkit-min-device-pixel-ratio: 2) { 
  min-width: 74vw;
  min-height: 53vh;
}


  @media all and (max-width: 1279px) and (orientation: portrait)  {
    height: 45vw;
    width: 80vh;
  }
  @media all and (max-width: 959px) {
    height: 54vh;
    width: 70vw;
    margin-left: 3em;
    }
    @media screen and (orientation: portrait) {
      height: 45vw;
      width: 80vh;
    }

}

.c-power-bi__Carcase-graph {
  height: 100%;
  width: 100%;
  
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: inline-block;
    width: 44vw;
    height: 53vh
  }
}


.c-power-bi__Carcase-error-container {
  display: flex;
  padding-top: 5vh;
  height: 50%;
}

.c-power-bi__inner-Carcase-error-container {
  display: table-cell;
  padding-top: 10vh;
}

.c-power-bi__Content{
  width: 100%;
  height: 80vh;
  margin-bottom: 1em;
  float: left;
  display: contents;
}

iframe {
  border: none !important;
}

.c-power-bi {
  &-share-history__graph {
    width: 100%;
    height: 100%;
  }
}

.c-power-bi-extended {
  @extend .c-power-bi__container;
  width: 100%;
}

.c-power-bi__inner-Carcase-container {
  display: table-cell;
  width: 100vw;
  
  .c-power-bi__header {
    font-size: 1.2em;
    margin-bottom: 1em;
    margin-top: 1em;
  }

  .c-power-bi__header-container {
    margin-bottom: 1em;
    margin-left: 5em;
    max-width: 37.5em;
    .c-power-bi-species-filter__container {
      display: inline-block;
      width: 65%;
      font-family: "Graphik";
      color: #bababa;

      .c-power-bi-species-filter__label {
        font-size: 0.8em;
        padding-right: 1em;
      }

      .c-power-bi-species-filter__item {
        > img {
          width: 2.5em;
          margin: 0 0.3em;

          &.show {
            display: inline;
          }

          &.hide {
            display: none;
          }
        }
      }
    }
    @media all and (min-width: 961px) and (max-width: 1280px) {
      .c-power-bi-species-filter__container {
        width: 50%;

        .c-power-bi-species-filter__item {
          > img {
            width: 2.5em;
            margin: 0 0.2em;
          }
        }
      }
    }
    @media all and (max-width: 960px) {
      margin-left: 2em;
      .c-power-bi-species-filter__container {
        width: 92%;

        .c-power-bi-species-filter__item {
          > img {
            width: 2.3em;
            margin: 0 0.2em;
          }
        }
      }
    }
  }

  

  .c-power-bi__embed-tab-container {
  float: left;
    width: 100vw;
    height: 100vh;
    display: grid;
    
    .c-power-bi__table {
      width: 93.75%;
      height: 700px;
      box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.16);
      margin-left: 1em;
      margin-bottom: 1em;
      float: left;
    }

    

    .c-power-bi__graph {
      width: 46.25%;
      height: 50vh;
      float: left;
      margin-left: 1em;
      margin-bottom: 1em;
      box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.16);
    }
    
  }

 

  .c-power-bi__embed-container {
    float: left;
    width: 100%;
    height: 90%;

    .c-power-bi__table {
      width: 93.75%;
      height: 700px;
      box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.16);
      margin-left: 1em;
      margin-bottom: 1em;
      float: left;
    }

    .c-power-bi__Tab {
      width: 93.75%;
      height: 700px;
      box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.16);
      margin-left: 6em;
      margin-bottom: 1em;
      float: left;
    }

    .c-power-bi__graph {
      width: 46.25%;
      height: 50vh;
      float: left;
      margin-left: 1em;
      margin-bottom: 1em;
      box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.16);
    }
  }

  @media only screen and (max-width: 1200px) {
    .c-power-bi__embed-container {
      .c-power-bi__graph {
        float: none;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
      }
      .c-power-bi__table {
        width: 1200px;
        float: none;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

.c-power-bi__width-adjustment {
  width: auto !important;
}

.c-power-bi__inner-container {
  display: inline-block;
  width: 100vw;
  overflow: auto;

  .c-power-bi__history-container {
    display: flex;
    flex-direction: column;

    width: 62vh;
    height: 52vh;

    float: left;
    margin: 1.5em 2em 1em 1em;
    background-color: white;
    box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.16);
    border-radius: 0.25em;

    &-title {
      margin: 2em;
    }

    @media only screen and (max-width: 1024px) {
        margin-left: 2em;
        width: 100vh;
        height: 100vh;
    }
  }

  .c-power-bi__header {
    font-size: 1.2em;
    margin-bottom: 1em;
    margin-top: 1em;
  }

  .c-power-bi__header-container {
    margin-bottom: 1em;
    margin-left: 5em;
    max-width: 37.5em;
    .c-power-bi-species-filter__container {
      display: inline-block;
      width: 65%;
      font-family: "Graphik";
      color: #bababa;

      .c-power-bi-species-filter__label {
        font-size: 0.8em;
        padding-right: 1em;
      }

      .c-power-bi-species-filter__item {
        > img {
          width: 2.5em;
          margin: 0 0.3em;

          &.show {
            display: inline;
          }

          &.hide {
            display: none;
          }
        }
      }
    }
    @media all and (min-width: 961px) and (max-width: 1280px) {
      .c-power-bi-species-filter__container {
        width: 50%;

        .c-power-bi-species-filter__item {
          > img {
            width: 2.5em;
            margin: 0 0.2em;
          }
        }
      }
    }
    @media all and (max-width: 960px) {
      margin-left: 2em;
      .c-power-bi-species-filter__container {
        width: 92%;

        .c-power-bi-species-filter__item {
          > img {
            width: 2.3em;
            margin: 0 0.2em;
          }
        }
      }
    }
  }

  .c-power-bi__embed-container {
    float: left;
    width: 100%;
    height: 90%;

    .c-power-bi__table {
      width: 93.75%;
      height: 700px;
      box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.16);
      margin-left: 1em;
      margin-bottom: 1em;
      float: left;
    }

    .c-power-bi__graph {
      width: 46.25%;
      height: 50vh;
      float: left;
      margin-left: 1em;
      margin-bottom: 1em;
      box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.16);
    }
  }
  
  

  @media (max-width: 1599px) and (min-width: 1280px)and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
    .c-power-bi__history-container {
      margin-left: 1em;
      width: 100vh!important;
    }
  }

  @media only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : portrait) { 
    .c-power-bi__history-container {
      margin-left: 1em;
      width: 50vh!important;
    }
   }

   @media only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px) 
  and (orientation : landscape) { .c-power-bi__history-container {
    margin-left: 1em;
    width: 100vh!important;
  }
 }

 @media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : landscape)
and (-webkit-min-device-pixel-ratio: 2) {.c-power-bi__history-container {
  margin-left: 1em;
  width: 65vh !important;
  //height: 38vh;
}
}

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait)
and (-webkit-min-device-pixel-ratio: 2) { .c-power-bi__history-container {
  margin-left: 2em;
  width: 49vh !important;
  height: 40vh;
}
}


  @media only screen and (max-width: 1200px) {
    .c-power-bi__embed-container {
      .c-power-bi__graph {
        float: none;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
      }
      .c-power-bi__table {
        width: 1200px;
        float: none;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}
