.c-stock-filter__form-control {
  display: inline-block;

  > div {
    &::before,
    &::after {
      display: none;
    }
  }

  #mui-component-select-selectedCategory {
    display: inline-block;
    right: 0;
    padding: 0 0 0 2em;
    line-height: 2em;
    vertical-align: middle;

    .category-item {
      .category-name {
        font-family: "ArcherSsm-Bold", "Roboto", sans-serif;

        &.selected {
        }
      }
    }
  }

  svg {
    display: inline-block;
    left: 0;
  }
}

#menu-selectedCategory {
  .category-item {
    margin: 0 auto;

    .category-name {
      font-family: "Graphik", "Roboto", sans-serif;
      font-size: 0.9em;

      &.selected {
      }
    }
  }
}
