// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}

/// Grid widths

@mixin grid2 {
  max-width: 16.666667% !important;
  flex-basis: 16.666667% !important;
}

@mixin grid3 {
  max-width: 25% !important;
  flex-basis: 25% !important;
}

@mixin grid4 {
  max-width: 33.333333% !important;
  flex-basis: 41.333333% !important;
}

@mixin grid5 {
  max-width: 41.666667% !important;
  flex-basis: 41.666667% !important;
}

@mixin grid6 {
  max-width: 50% !important;
  flex-basis: 50% !important;
}

@mixin grid7 {
  max-width: 58.333333% !important;
  flex-basis: 58.333333% !important;
}

@mixin grid8 {
  max-width: 66.666667% !important;
  flex-basis: 66.666667% !important;
}

@mixin grid9 {
  max-width: 75% !important;
  flex-basis: 75% !important;
}

@mixin grid10 {
  max-width: 83.333333% !important;
  flex-basis: 83.333333% !important;
}

@mixin grid11 {
  max-width: 91.666667% !important;
  flex-basis: 91.666667% !important;
}

@mixin grid12 {
  max-width: 100% !important;
  flex-basis: 100% !important;
}