.c-grades__container {
  &.single-category {
    margin-top: 2em;
  }

  &.multiple-category {
    margin-top: 0.5em;
  }

  .c-grades__stock-filter {
    margin: 0.5em 2em;
    text-align: right;
  }

  .c-grades__panel {
    box-shadow: none;

    &::before {
      margin: 0 2em;
      background: none !important;
    }

    .c-grades__summary {
      padding: 0;

      .c-grades__header {
        min-width: 100%;
        font-size: 0.8em;
        padding: 0.25em 2em;

        .c-grades__grade {
          width: 50%;
          display: inline-block;
          font-family: "ArcherSSm-Bold", "Roboto", sans-serif;

          &.beef {
            color: #0d8c49;
          }

          &.bobby {
            color: #0d8c49;
          }

          &.deer {
            color: #a12041;
          }

          &.sheep {
            color: #6ab445;
          }
        }

        .c-grades__quantity {
          width: 50%;
          display: inline-block;
          text-align: right;
        }

        .c-grades__divider {
          border-bottom: 1px solid rgba(0, 0, 0, 0.16) !important;
          padding-bottom: 0.5em;
        }
      }

      .c-grades__more-icon-bar {
        min-width: 1.75em;
        display: inline-block;
        border-radius: 4em;
        height: 1em;
        margin: 0.75em 0;

        > svg {
          color: #fff;
          width: 0.75em;
          height: 0.75em;
          margin-left: 0.25em;
        }

        &.beef {
          background-color: #0d8c49;
        }

        &.bobby {
          background-color: #0d8c49;
        }

        &.deer {
          background-color: #a12041;
        }

        &.sheep {
          background-color: #6ab445;
        }
      }
    }

    .c-grades__details {
      display: inline-block;
      padding: 1em 2em 2em 2em;
      -moz-box-shadow: inset 0 1em 0.5em -0.5em #efefef, inset 0 -1em 0.5em -0.5em #efefef;
      -webkit-box-shadow: inset 0 1em 0.5em -0.5em #efefef, inset 0 -1em 0.5em -0.5em #efefef;
      box-shadow: inset 0 1em 0.5em -0.5em #efefef, inset 0 -1em 0.5em -0.5em #efefef;
      width: 100%;

      * {
        display: inline-block;
      }

      .c-grades__property {
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.16);
        padding: 0.5em 0;

        > * {
          width: 50%;
          font-size: 0.8em;
        }

        .c-grades__property-name {
          font-family: "Graphik", "Roboto", sans-serif;
          color: #9b9b9b;
        }
      }
    }
  }

  .c-grade-state__panel {
    box-shadow: none;

    &::before {
      margin: 0 2em;
      background: none !important;
    }

    .c-grade-state__summary {
      padding: 0;

      .c-grade-state__header {
        width: 100%;
        font-size: 0.8em;
        padding: 0 2em;

        .c-grade-state__title {
          width: 50%;
          display: inline-block;
          font-family: "ArcherSSm-Bold", "Roboto", sans-serif;
          color: #e0544a;
        }

        .c-grade-state__quantity {
          width: 50%;
          display: inline-block;
          text-align: right;
        }

        .c-grade-state__divider {
          border-bottom: 1px solid rgba(0, 0, 0, 0.16) !important;
          padding-bottom: 0.5em;
        }
      }

      .c-grade-state__more-icon-bar {
        min-width: 1.75em;
        display: inline-block;
        border-radius: 4em;
        height: 1em;
        margin: 0.75em 0;
        background-color: #e0544a;

        > svg {
          color: #fff;
          width: 0.75em;
          height: 0.75em;
          margin-left: 0.25em;
        }
      }
    }

    .c-grade-state__details {
      display: inline-block;
      padding: 1em 2em 2em 2em;
      -moz-box-shadow: inset 0 1em 0.5em -0.5em #efefef, inset 0 -1em 0.5em -0.5em #efefef;
      -webkit-box-shadow: inset 0 1em 0.5em -0.5em #efefef, inset 0 -1em 0.5em -0.5em #efefef;
      box-shadow: inset 0 1em 0.5em -0.5em #efefef, inset 0 -1em 0.5em -0.5em #efefef;
      width: 100%;

      * {
        display: inline-block;
      }

      .c-grade-state__property {
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.16) !important;
        padding: 0.5em 0;

        > * {
          width: 50%;
          font-size: 0.8em;
        }

        .c-grade-state__property-name {
          font-family: "Graphik", "Roboto", sans-serif;
          color: #9b9b9b;
        }

        .c-grade-state__property-value {
          * {
            font-family: "Graphik-Semibold", "Roboto", sans-serif;
            vertical-align: middle;
          }

          div {
            width: 100%;
          }
        }
      }
    }
  }

  .c-grades__carcase-report {

    padding: 0 1.5em;
    margin: 0.5em 0;

    button {
      color: #427b5d !important;
      border: 1px solid #427b5d !important;
      font-family: "Graphik-Semibold", "Roboto", sans-serif !important;
      font-size: 0.9em !important;
      letter-spacing: 0.05em !important;
      border-radius: 0 !important;
      height: 3em;

      &:hover {
        background-color: rgba(66, 123, 93, 0.07) !important;
      }
    }
    
  }

  .c-grades__total-tally {
    width: 100%;
    margin: 2em 0;
    padding: 0 1.5em;
    margin: 0.5em 0;

    &::before {
      margin: 0 2em;
      top: -1px;
      left: 0;
      right: 0;
      height: 1px !important;
      content: "";
      opacity: 1 !important;
      position: absolute;
      transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      background-color: rgba(0, 0, 0, 0.12) !important;
    }

    > * {
      display: inline-block;
      width: 50%;
      font-family: "ArcherSsm-Bold", "Roboto", sans-serif;
    }

    .c-grades__tally-title {
      font-size: 0.8em;
    }

    .c-grades__tally-value {
      text-align: right;
      font-size: 1em;
    }

    .c-grades__divider {
      border-bottom: 1px solid rgba(0, 0, 0, 0.16) !important;
      padding-bottom: 0.5em;
      width: 100%;
    }
  }
}

#menu-selectedAnimalCategory,
#menu-selectedKillSheetNumber {
  * {
    font-family: "Graphik-Semibold", "Roboto", sans-serif;
  }

  > div {
    min-width: 7.5em !important;
  }
}
