.c-my-shares__container {
  display: inline-block;
  background: rgba(0, 0, 0, 0.02);
  width: 100%;
  height: calc(100vh - 3.25em);
  padding-bottom: 5em;
  scrollbar-width: none;

  .c-my-shares__tabs::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
  }

  .c-my-shares__tabs {
    display: inline-flex;
    margin: 1em 2em 1em 5em;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    [aria-selected="false"] {
      color: #ccc !important;
    }

    .c-my-shares__tab {
      font-family: "Graphik-SemiBold", "Roboto", sans-serif;
      font-size: 1.2em;
      padding: 0.5em 0;
      text-align: left;
      margin-right: 1em;
      min-width: fit-content;
      color: #000;

      > span {
        > span {
          padding: 0;
        }
      }
    }

    .c-my-shares__tab-shrinkable {
      min-width: 0;
    }

    > div {
        > span {
          height: 0.25em;
          background-color: #427b5d;
        }
      }
    }
  }

.c-my-shares__load-error-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.c-my-shares-item {
  &__container {
    display: inline-flex;
    flex-direction: column;
  }
  &__columns-container {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__container-item {
    display: inline-flex;
    flex-direction: column;
  }

  &__container-item-history {
    display: inline-flex;
    flex-direction: column;
    padding-left: 4em;
  }

  &__responsive-container {
    display: inline-flex;
  }
}

@media all and (max-width: 1650px) {
  .c-my-shares-item {
    &__responsive-container {
      flex-direction: row;
    }
    &__container-item-history{
      padding-left: 4em;
    }
  }
}

@media all and (max-width: 1366px) {
  .c-my-shares-item {
    &__responsive-container {
      flex-direction: column;
    }
    &__container-item-history{
      padding-left: 4em;
    }
  }
}

@media all and (max-width: 1024px) {
  .c-my-shares-item {
    &__responsive-container {
      flex-direction: column;
    }
    &__container-item-history{
      padding-left: 0em;
    }
  }
}

@media all and (max-width: 768px) {
  .c-my-shares-item {
    &__responsive-container {
      flex-direction: column;
    }
    &__container-item-history{
      padding-left: 4em;
    }
    &__container-item{
      padding-left: 4em;
    }
  }
}


@media all and (max-width: 1024px) {
  .c-my-shares__container {
    .c-my-shares__tabs {
      margin-left: 2em;
      .c-my-shares__tab {
        font-size: 0.89em;
      }
    }
    &__container-item-history{
      padding-left: 0;
    }
  }

  .c-my-shares-item__columns-container {
    flex-direction: column;
  }
}
