.c-settings {
  &__container {
    width: 100vw;
    height: 100vh;
    overflow-x: auto;
    overflow-y: hidden;
    background: rgba(0, 0, 0, 0.02);
  }

  &__list {
    display: inline-block;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    min-width: 77.5em;
    overflow: "-moz-scrollbars-none";
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0 !important;
    }
  }

  &__action {
    display: inline-block;
    vertical-align: top;
    height: 100vh;
    overflow: auto;
    margin: 1em 0 0 1em;
    width: 50%;
    padding: 1em 0 0 1em;
  }

  &__header {
    text-transform: uppercase;
    font-size: 1.5em;
    padding: 0.5em 0 0 2em;
    margin: 1em 1.5em 0 1.5em;
  }
}

.c-settings-item {
  &__container {
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 4em;
    margin: 2em 2em 2em 5em;
    background-color: white;
    border-radius: 0.3em;
    box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.16);
    display: inline-block;
    margin-bottom: 8em;
    max-width: 40%;
    min-width: 25em;
    height: calc(100vh - 10em);
    overflow: auto;
    overflow: "-moz-scrollbars-none";
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 35%;

    &::-webkit-scrollbar {
      width: 0 !important;
    }
  }

  &__header {
    text-transform: uppercase;
    font-size: 1.2em;
    padding: 2em 2.5em 1em 1.5em;
    color: #bababa;
    font-family: "Graphik-Medium", "Roboto", sans-serif;
    letter-spacing: 0.1em;
  }

  &__body-container {
    :hover {
      background-color: rgba(66, 123, 93, 0.07);
    }
  }

  &__body {
    font-family: "Graphik-Medium";
    font-size: 1em;
    padding: 1.5em;
    color: rgb(51, 51, 51);
  }
}

.c-settings__action-panel {
  width: 100%;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}

@media all and (min-width: 1920px) {
  .c-settings {
    &__action {
      width: 55%;
    }
  }
}

@media all and (max-width: 1024px) {
  .c-settings {
    &__header {
      margin: 1em 1.5em 0 0.5em;
      font-size: 1.2em;
    }

    &__list {
      min-width: 60em;
    }
  }

  .c-settings-item {
    &__container {
      margin: 1em 1em 2em 3em;
    }
  }
}
