// custom rules
.DayPickerNavigation_customButton {
    position: absolute;
    top: 1em;
    background-color: white;
    box-shadow: 0em 0.4em 0.65em 0 rgba(0, 0, 0, 0.22);
    text-align: center;
    width: 1.5em;
    height: 1.5em;

    > svg {
      vertical-align: sub;
    }

    &.left {
      left: 1em;
    }

    &.right {
      right: 1em;
    }
}

.DayPickerHeader {
    height: 2.2em;
    line-height: 2.2em;
    background-color: rgb(66, 123, 93);
    color: white;
    text-align: center;
    font-family: "Graphik-Semibold", "Roboto", "sans-serif";
    font-size: 0.8em;
}

// react-dates overrides below
.DateRangePicker {
    height: 100%;
    > div {
        height: 100%;
    }

    &_picker {
        border-radius: 0.3em;
        margin-top: 0.5em;
        margin-left: 1.5em;
    }
}

.DateRangePickerInput {
    height: 100%;

    &_arrow {
        color: rgba(51, 51, 51, 0.3);
        display: inline-flex;
    }
}

.DateInput {
    height: 100%;
    margin: 0 1em;
    padding: 0 0.5em;
    width: 7em;

    &_input {
        height: 100%;
        font-family: "Graphik", "Roboto", "sans-serif";
        padding: 0.5em 0;
        text-align: center;

        &::-webkit-input-placeholder {
            color: rgba(51, 51, 51, 0.3);
        }
        &:-ms-input-placeholder {
            color: rgba(51, 51, 51, 0.3);
        }
        &::-moz-placeholder {
            color: rgba(51, 51, 51, 0.3);
            opacity: 1;
        }
        &:-moz-placeholder {
            color: rgba(51, 51, 51, 0.3);
            opacity: 1;
        }
        &::placeholder {
            color: rgba(51, 51, 51, 0.3);
        }

        &__focused {
            border-bottom-color: rgb(66, 123, 93);
        }
    }

    &_fang {
        display: none;
    }
}

.DayPicker {
    box-shadow: 0em 0.4em 0.65em 0 rgba(0, 0, 0, 0.22);

    &__withBorder {
        border-radius: 0.3em;
        overflow: hidden;
    }

    &_weekHeader:first-of-type {
        border-bottom: 0.05em solid rgba(112, 112, 112, 0.08);
        padding-left: 1.1em !important;
        left: -0.45em !important;
        right: -0.05em;
    }

    &_weekHeader_li {
        visibility: hidden;
        font-family: "Graphik-Medium", "Roboto", "sans-serif";
        font-size: 0;
        color: rgba(51, 51, 51, 0.3);
        &::first-letter {
            visibility: visible;
            font-size: 9px;
        }
    }
}

.CalendarMonth {
    &_table {
        font-family: "Graphik", "Roboto", "sans-serif";
        margin-top: 0.5em;

        td {
            border: none;
            font-size: 0.6em;
        }
    }

    &_caption {
        font-family: "Graphik-Semibold", "Roboto", "sans-serif";
    }
}

.CalendarDay {
    &__selected {
        background-color: rgb(66, 123, 93);

        &_span {
            background-color: rgba(66, 123, 93, 0.75);

            &:hover {
                border: none;
                background-color: rgba(66, 123, 93, 0.5);
            }
        }

        &:hover {
            border: none;
            background-color: rgba(66, 123, 93, 0.85);
        }
    }

    &__hovered_span {
        background-color: rgba(66, 123, 93, 0.75);
        color: white;
        &:hover {
            border: none;
            background-color: rgba(66, 123, 93, 0.5);
            color: white;
        }
    }
}
