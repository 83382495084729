.c-farm-details__container {
  position: absolute;
  left: 5em;
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(90deg);
  transform: translateX(-50%) translateY(-50%) rotate(90deg);
  top: 50%;
  width: 13em;
  border-radius: 1em;
  line-height: 2em;
  text-align: center;
  color: #fff;
  display: inline-block;

  &.final {
    background: #427b5d;
  }

  &.interim {
    background: #b0b569;
  }

  .c-farm-details__farm-name {
    display: inline-block;
    font-size: 0.75em;
    overflow: hidden;
    padding-left: 1em;
    text-align: left;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
    width: 65%;
  }

  .c-farm-details__supplier-id {
    display: inline-block;
    font-family: "Graphik-Medium", "Roboto", sans-serif;
    font-size: 0.75em;
    padding-right: 1em;
    text-align: right;
    vertical-align: middle;
    width: 35%;
  }
}

@media all and (min-width: 961px) and (max-width: 1280px) {
  .c-farm-details__container {
    width: 12em;
  }
}

@media all and (max-width: 960px) {
  .c-farm-details__container {
    &.final {
      width: 21em;
    }

    &.interim {
      width: 20em;
    }
  }
}
