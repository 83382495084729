.c-my-account {
  &__container {
    display: inline-block;
    background: rgba(0, 0, 0, 0.02);
    width: 100%;
    height: 100vh;
    overflow: auto;
    -ms-overflow-style: none;
    overflow: "-moz-scrollbars-none";
    padding-bottom: 5em;
    scrollbar-width: none;
  }

  &__header {
    font-size: 1.5em;
    margin-left: 3.5em;
    margin-bottom: 0.5em;
    text-transform: uppercase;
  }
}

.c-my-account__load-error-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.c-my-account__container {
  .c-my-account__tabs {
    display: inline-flex;
    margin: 1em 2em 1em 5em;

    [aria-selected="false"] {
      color: #ccc !important;
    }

    .c-my-account__tab {
      flex-basis: auto; // Added to override the Mui styling - fixed squashed text
      font-family: "Graphik-SemiBold", "Roboto", sans-serif;
      font-size: 1.5em;
      padding: 0.5em 0;
      text-align: left;
      margin-right: 1em;
      min-width: auto;
      color: #000;

      > span {
        > span {
          padding: 0;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }

    > div {
        > span {
          height: 0.25em;
          background-color: #427b5d;
        }
      }
    }
  }
.c-my-account-item {
  &__container {
    padding: 2em;
    padding-bottom: 4em;
    margin: 1.5em 5em 2em 5em;
    background-color: white;
    border-radius: 0.3em;
    box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.16);
    min-width: 540px;
    max-width: 1080px;
  }

  &__field {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    padding: 1.5em;
    border-bottom: 1px solid rgb(239, 239, 239);
  }

  &__label {
    width: 50%;
    white-space: nowrap;
    font-family: "Graphik";
    font-size: 0.9em;
    text-align: left;
    color: rgb(155, 155, 155);
  }

  &__value {
    width: 50%;
    font-family: "Graphik-Medium";
    font-size: 0.9em;
    text-align: left;
    color: $dark-grey;
    overflow-anchor: auto;

    &-array {
      width: 50%;
      font-family: "Graphik-Medium";
      font-size: 0.9em;
      text-align: left;
      color: $dark-grey;
      margin-bottom: 0.5em;
      white-space: nowrap;
    }

    &-array-last {
      @extend .c-my-account-item__value-array;
      margin-bottom: 0em;
    }

    &-array-container {
      flex: 1;
    }
  }
}

.c-share-holder {
  &__header {
    @extend .c-my-account__header;
  }
}
.c-share-holder-item {
  &__container {
    @extend .c-my-account-item__container;
  }

  &__row {
    &-header {
      display: flex;
      flex: 1 1;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 1.5em;
      padding-left: 1.5em;
      padding-right: 1.5em;
      padding-bottom: 1em;
      border-bottom: 1px solid #efefef;
    }
    &-body {
      @extend .c-share-holder-item__row-header;
      padding: 1.5em;
    }
  }

  &__col {
    &-header {
      flex: 1;
      font-size: 0.9em;
      font-family: "Graphik";
      color: rgb(155, 155, 155);

      &-right {
        // @extend .c-share-holder-item__col-header;
        font-size: 1em;
        font-family: "Graphik";
        color: rgb(155, 155, 155);
        width: 50%;
        text-align: right;
      }
    }
    &-body {
      flex: 1;
      font-size: 0.9em;
      font-family: "Graphik-Medium";
      color: $dark-grey;

      &-right {
        // @extend .c-share-holder-item__col-body;
        font-size: 1em;
        font-family: "Graphik-Medium";
        color: $dark-grey;
        width: 50%;
        text-align: right;
      }
    }
  }
}

/* Media queries - Responsive item on screens less than or equal to 1160px wide */
@media all and (max-width: 1160px) {
  .c-my-account-item {
    &__field {
      flex-direction: column;
    }
    &__label {
      margin-bottom: 1em;
    }
  }
}

/* Media queries - Responsive item on screens less than or equal to 1024px wide */
@media all and (max-width: 1024px) {
  .c-my-account {
    &__header {
      font-size: 1.2em;
      margin-left: 1.75em;
    }
  }

  .c-my-account-item {
    &__container {
      margin: 1em 5em 2em 2em;
    }

    &__field {
      flex-direction: column;
    }
    &__label {
      margin-bottom: 1em;
    }
  }

  .c-my-account__container {
    .c-my-account__tabs {
      margin-left: 2em;
      .c-my-account__tab {
        font-size: 1em;
      }
    }
  }
}
