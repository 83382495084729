.p-error-boundary-page {
  background: url(/public/images/sff_bg.png) no-repeat center center fixed;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100vh;
  background-position: bottom;
}

.p-error-boundary-container {
  position: absolute;
  width: 90%;
  max-width: 525px;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);

  &__header {
    font-family: "Graphik-Semibold", "Roboto", sans-serif;
    margin: 2vh auto 5vh auto;
    max-width: 325px;
  }

  @media screen and (orientation: landscape) {
    &__header {
      margin: 4.5vh auto 5vh auto;
    }
  }

  &__logo {
    width: 70%;
    display: block;
    margin: 3em auto 5em auto;
  }

  &__error {
    // position: relative;
    // width: 90%;
    // max-width: 525px;
    // top: 5em;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // -ms-transform: translate(-50%, -50%);
    // -webkit-transform: translate(-50%, -50%);
    // -moz-transform: translate(-50%, -50%);
    // -o-transform: translate(-50%, -50%);
  }

  &__content {
    background-color: #fff;
    padding: 2em;
    padding-bottom: 3em;
    margin-top: -5vh !important;
    margin-bottom: 2em;
    background-color: white;
    border-radius: 0.3em;
    box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.16);
  }

  h2 {
    font-family: "Graphik", "Roboto", sans-serif;
    font-size: 1.4em;
    color: #ff554a;
    text-align: center;
    padding-bottom: 0.3em;
  }

  &__text {
    font-family: "Graphik", "Roboto", sans-serif;
    font-size: 0.8em;
    text-align: center;
    padding: 0em 2.2em 3em 2.2em;
  }

  &__button-container {
    width: 55%;
    margin: 0 auto 1em;

    button {
      font-family: Graphik-Semibold, Roboto, sans-serif;
      border: none;
      padding: 12px 18px;
      text-transform: uppercase;
      cursor: pointer;
      color: #4a8063;
      background-color: #fff;
      outline: none;
      width: 100%;
      -webkit-font-smoothing: antialiased;
      background-position: 50%;
      -webkit-transition: background 0.8s;
      transition: background 0.8s;
      font-size: 0.75em;
      margin-bottom: 2em;
      font-weight: 600;
      letter-spacing: 0.1em;
    }
  }

  &__links-container {
    width: 70%;
    margin: 0 auto;
  }

  &__link-text {
    float: left;
    color: #fff;
    font-weight: 600;
    font-size: 0.9rem;
    text-decoration: underline;
    font-family: Graphik-Medium;
    font-weight: 100;

    &:hover {
      color: #fff;
    }

    &.contact-us {
      float: right;
    }
  }
}
