.c-banner {
  &__container {
    width: 100%;
    height: 56px;
    box-shadow: 4px 4px 11px 0 rgba(0, 0, 0, 0.09);
    display: flex;
    align-items: center;
    padding-left: 30px;
  }

  &__background-low {
    background-color: $low;
  }

  &__background-medium {
    background-color: $medium;
  }

  &__background-high {
    background-color: $high;
  }

  &__container:hover {
    cursor: pointer;
    text-decoration: none;
  }

  &__link:hover {
    text-decoration: none;
  }
  &__link {
    text-decoration: none;
  }

  &__icon {
    display: flex;
    margin-right: 18px;
  }

  &__message-container {
    display: flex;
    align-items: center;
  }

  &__title {
    font-family: Graphik;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.8px;
    text-align: left;
    color: #ffffff;
    padding-right: 8px;
  }
  &__description {
    font-family: Graphik;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.94;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
  }
  &__close {
    margin-left: auto;
    width: 45px;
    display: flex;
    color: #f4f4f4;
    object-fit: contain;
  }
}

@media all and (max-width: 1024px) {
  .c-banner {
    &__container {
      align-items: flex-start;
      padding: 12px 20px 12px 30px;
      height: 75px;
    }
    &__icon, &__close {
      margin-top: 3px;
    }
    &__message-container {
      flex-grow: 1;
      flex-direction: column;
      height: 100%;
    }
    &__title, &__description {
      flex-grow: 1;
      align-items: center;
      display: flex;
      text-align: left;
      width: 100%;  
    }
    &__close-icon {
      margin-left: auto;
    }
  }
}
