.c-loading {
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    overflow: auto;
    -ms-overflow-style: none;
    overflow: "-moz-scrollbars-none";
    background-color: rgba(255, 255, 255, 0.8);
    scrollbar-width: none;
  }
  &__spinner {
    width: 400px;
    margin-bottom: 5em;
  }
}
