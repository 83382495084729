.p-payments__container {
  background: rgba(0, 0, 0, 0.02);
  overflow-y: hidden;
  height: calc(100vh - 3.25em);

  .p-payments__tabs {
    display: inline-flex;
    margin: 1em 2em 1em 5em;

    [aria-selected="false"] {
      color: #ccc !important;
    }

    .p-payments__tab {
      font-family: "Graphik-SemiBold", "Roboto", sans-serif;
      color: #000;
      font-size: 1.2em;
      padding: 0.5em 0;
      text-align: left;
      margin-right: 1em;
      min-width: fit-content;

      > span {
        > span {
          padding: 0;
        }
      }
    }

    > div {
        > span {
          height: 0.25em;
          background-color: #427b5d;
        }
      }
    }
  }

@media all and (max-width: 1024px) {
  .p-payments__container {
    .p-payments__tabs {
      .p-payments__tab {
        font-size: 1em;
      }
    }
  }
}
