.c-shares-history-list {
  &__empty-container {
    width: 100%;
    max-width: 50em;
    min-width: 40em;
    height: 72vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__empty-text {
    font-family: "Graphik";
    font-weight: 100;
    font-size: 1.1em;
    text-align: center;
    margin-top: 2em;
  }
}

.c-share-history {
  &__container {
    background-color: white;
    width: 31em;
    border-radius: 0.25em;
    margin: 1.5em 5em 0 5em;
    padding: 0.6em;
    box-shadow: 0 0.15em 0.8em 0 rgba(0, 0, 0, 0.16);
  }

  &__tooltip-container {
    text-align: right;
  }

  &__shares-stock-units {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.05em dashed black;
    margin: 0 1.4em 0 1.4em;
    padding-bottom: 1.2em;
  }

  &__shares-value {
    font-family: "Graphik-Medium", "Roboto", "sans-serif";
    font-size: 1.6em;
    letter-spacing: 0.03em;
  }

  &__stock-units-value {
    font-family: "Graphik-Medium", "Roboto", "sans-serif";
    font-size: 1.6em;
    letter-spacing: 0.03em;
    text-align: right;
  }

  &__shares-label {
    font-family: "Graphik", "Roboto", "sans-serif";
    font-size: 0.8em;
    letter-spacing: 0.015em;
  }

  &__stock-units-label {
    font-family: "Graphik", "Roboto", "sans-serif";
    font-size: 0.8em;
    letter-spacing: 0.015em;
    text-align: right;
  }

  &__required-shares-year-end {
    display: flex;
    justify-content: space-between;
    margin: 0 1.4em 1.4em 1.4em;
    padding-top: 1.2em;
  }

  &__required-shares-value {
    font-family: "Graphik-Medium", "Roboto", "sans-serif";
    font-size: 1.1em;
    letter-spacing: 0.021em;
  }

  &__year-end-value {
    font-family: "Graphik-Medium", "Roboto", "sans-serif";
    font-size: 1.1em;
    letter-spacing: 0.021em;
    text-align: right;
  }

  &__required-shares-label {
    font-family: "Graphik", "Roboto", "sans-serif";
    font-size: 0.8em;
    letter-spacing: 0.015em;
  }

  &__year-end-label {
    font-family: "Graphik", "Roboto", "sans-serif";
    font-size: 0.8em;
    letter-spacing: 0.015em;
    text-align: right;
  }
}

@media all and (max-width: 1024px) {
  .c-share-history {
    &__container {
      margin-left: 2em;
      width: 27em;
    }
  }
}
