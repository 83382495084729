.c-patronage {
  &__container {
    display: flex;
    flex-direction: column;
    width: 31em;
    height: 27.5em;
    margin: 1.5em 2em 1em 0;
    background: #fff;
    bottom: -5em;
    border-radius: 0.3em;
    box-shadow: 0 0.2em 0.5em 0.05em rgba(0, 0, 0, 0.16);
  }

  &__header {
    background: #417a5d;
    height: 2.6em;
    line-height: 2.6em;
    border-radius: 0.3em 0.3em 0 0;
    padding-left: 1em;
    font-family: "Graphik-Semibold", "Roboto", "sans-serif";
    font-weight: bold;
    font-size: 1em;
    letter-spacing: 0.075em;
    color: #fff;
    box-shadow: inherit;
  }

  &__graph {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    padding-top: 1.5em;
  }

  &__graph-header-container {
    padding: 0 0.67em;
    width: 87.5%;
    -ms-grid-row: 1;
    grid-row: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-family: "Graphik", "Roboto", "sans-serif";
    font-size: 1.2em;
    letter-spacing: 0.029em;
    transform: translate(0.2em, 0.35em);
  }

  &__graph-header-bold {
    font-family: "Graphik-Semibold", "Roboto-Bold";
    margin-right: 0.4em;
  }

  &__graph-stem-container {
    -ms-grid-row: 2;
    -ms-grid-row-span: 2;
    -ms-grid-column: 1;
    grid-row: 2 / span 2;
    grid-column: 1;
    height: 5.65em;
    display: flex;
    flex-direction: column-reverse;
    width: calc(85% + 0.05em);
    flex: 4;
    border-right: dashed black 0.05em;
    position: relative;
    z-index: 2;
  }

  &__graph-subheader {
    font-family: "Graphik", "Roboto", "sans-serif";
    font-size: 0.6em;
    letter-spacing: 0.011em;
    text-align: right;
    margin-right: 2.6em;
    margin-bottom: 1.2em;
  }

  &__graph-left-flag {
    display: flex;
    justify-content: flex-end;
    height: 66.667%;
    width: 111%;
    border-right: solid black 0.05em;
    transform: translateX(0.05em);
    transition: width 0.4s linear 0s;
    z-index: 2;
  }

  &__graph-left-flag-hidden {
    height: 66.667%;
  }

  &__info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(65, 122, 93, 0.1);
    padding: 1.2em 1.7em 1.2em 1.7em;
    box-shadow: 0 0.2em 0.2em -0.05em rgba(0, 0, 0, 0.16);
  }

  &__info-text {
    display: inline-flex;
    width: 27em;
    color: #417a5d;
    font-weight: normal;
    font-size: small;
    font-family: "Graphik-Medium", "Roboto", "sans-serif";
  }

  &__calculator {
    display: flex;
    flex: 1;
    justify-content: space-around;
    align-items: center;
    background: rgba(51, 51, 51, 0.06);
    border-radius: 0 0 0.3em 0.3em;
    font-family: "Graphik-Semibold", "Roboto", "sans-serif";
    font-size: small;
    text-align: center;
    color: rgb(51, 51, 51);
    letter-spacing: 0.035em;
  }

  &__calculator-stock-units {
    p {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__tooltip {
    vertical-align: middle;
  }

  &__tooltip-animal {
    border-bottom: 0.05em dotted black;
    text-align: right;
    margin: 0.5em;
    margin-top: -3em;
    padding: 1em;
  }

  &__img {
    width: 3em;
    height: 3em;
    margin-right: -5em;
    margin-left: 0.5em;
    margin-bottom: -0.75em;
  }

  &__eligibility-error {
    width: 21.635em;
    height: 0em;
    font-family: "Graphik", "Roboto", "sans-serif";
    font-size: 0.8em;
    text-align: left;
    display: block;
    position: relative;
    top: 1em;
    left: 0.15em;
    color: rgb(224, 84, 74);
  }
}

@media all and (max-width: 1650px) {
  .c-patronage {
    &__container {
      margin-left: 5em;
    }
  }
}

@media all and (max-width: 1024px) {
  .c-patronage {
    &__container {
      margin-left: 2em;
    }
  }
}
