.c-progress-indicator {
  height: 3em;
  width: inherit;
  text-align: center;

  &.hide {
    display: none;
  }
}

[id^="c-progress__timeline"] {
  @extend .c-progress-indicator;
  margin-left: 3.5em;
  margin-top: -6em;
}

.c-progress__stocks-container {
  max-width: 40em;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

[id^="c-progress__stocks"] {
  @extend .c-progress-indicator;
}

@media all and (min-width: 1920px) {
  .c-progress__stocks-container {
    max-width: 100%;
  }
}

.c-progress__whats-new-container {
  @extend .c-progress__stocks-container;
  height: 75%;
}

[id^="c-progress__whats-new"] {
  @extend .c-progress-indicator;
}

[id^="c-progress__articles"] {
  @extend .c-progress-indicator;
}

[id^="c-progress__payments"] {
  @extend .c-progress-indicator;
}

[id^="c-progress__invoices"] {
  @extend .c-progress-indicator;
}
