.c-stocks__container {
  display: inline-block;
  width: 100%;
  height: 95vh;
  overflow: auto;
  -ms-overflow-style: none;
  overflow: "-moz-scrollars-none";
  min-width: 768px;

  @media all and (min-width: 1920px) {
    width: 50%;
  }

  &::-webkit-scrollbar {
    width: 0 !important;
  }

  #c-stocks-progress__animation-container {
    height: 3em;
    width: inherit;
    text-align: center;
    margin-top: -6em;

    &.hide {
      display: none;
    }
  }

  .c-stocks__empty-kill-sheets-container {
    margin: 2em 2.5em;
    text-align: center;

    .c-stocks__empty-kill-sheets-text {
      font-family: "Graphik", "Roboto", sans-serif;
      font-weight: 100;
      font-size: 1.5em;
      margin-bottom: 1em;
    }

    .c-stocks__refresh-button-container {
      > button {
        color: #fff;
        background: #427b5d;
      }

      .c-stocks__refresh-icon {
        margin-right: 0.5em;
      }
    }
  }
}

.c-stocks__container-divider {
  width: 1em;
  background: #fff;
  box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.13);
}

.c-stocks__header {
  font-size: 1.5em;
  margin-bottom: -0.5em;
  text-transform: uppercase;
}

.c-stocks__header-container {
  display: block;
  margin-left: 5em;
  max-width: 45em;
}

.c-stocks__legend {
  width: 35%;
  display: inline-block;

  .c-stocks__legend-icon {
    display: inline-block;
    border-radius: 50%;
    border-width: 0;
    height: 0.8em;
    width: 0.8em;
    vertical-align: middle;
    margin-right: 10px;
  }

  .c-stocks__legend-text {
    margin-right: 1em;
    font-size: 0.8em;
    color: #9b9b9b;
    font-family: "Graphik-Medium";
  }
}

.c-stocks__legend-icon {
  background-color: #b0b569;
}

/* The actual timeline (the vertical ruler) */
.c-stocks__timeline {
  position: relative;
  top: 2em;
  margin-bottom: 5em;

  &::after {
    content: "";
    position: absolute;
    width: 1px;
    background-color: #ccc;
    top: 1em;
    bottom: 0;
    left: 2.6em;
    height: calc(
      100% + 4em
    ); /* The height needs to be set a little higher to accommodate the progress component on Safari browsers */
    min-height: 100vh;
  }

  .c-stocks-fab {
    background: #fff;
    color: rgba(0, 0, 0, 0.16);
    width: 2.5em;
    height: 1em;
    position: fixed;
    bottom: 0;
    margin: 2.5em;
    z-index: 100;
  }
}

/* Container around content */
.c-stocks__content-container {
  width: 37em;
  height: 9.5em;
  padding: 0.5em 1em;
  position: relative;
  background-color: inherit;

  &.beef {
    /* The circles on the timeline */
    &::after {
      content: "";
      position: absolute;
      width: 25px;
      height: 25px;
      background-color: #0d8c49;
      border: 4px solid #0d8c49;
      top: 4.4em;
      border-radius: 50%;
      z-index: 1;
    }
  }

  &.deer {
    /* The circles on the timeline */
    &::after {
      content: "";
      position: absolute;
      width: 25px;
      height: 25px;
      background-color: #a12041;
      border: 4px solid #a12041;
      top: 4.4em;
      border-radius: 50%;
      z-index: 1;
    }
  }

  &.sheep {
    /* The circles on the timeline */
    &::after {
      content: "";
      position: absolute;
      width: 25px;
      height: 25px;
      background-color: #6ab445;
      border: 4px solid #6ab445;
      top: 4.4em;
      border-radius: 50%;
      z-index: 1;
    }
  }

  .c-stocks__period {
    position: absolute;
    top: 50%;
    margin: auto;
    font-size: 0.8em;
    transform: translateX(-50%) translateY(-50%) rotate(90deg);
    margin-left: 0.25em;
  }
}

.c-stocks__initial-year-container {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    width: 0.65em;
    height: 0.65em;
    background-color: #ccc;
    top: 0.35em;
    border-radius: 50%;
    z-index: 1;
    left: 2.3em;
  }

  .c-stocks__initial-year {
    transform: translateX(-50%) translateY(-50%) rotate(90deg);
    width: 1em;
    font-size: 1em;
    color: #ccc;
    margin-left: 1em;
  }
}

/* Place the container to the left */
.c-stocks__left {
  left: 0;
}

/* Place the container to the right */
.c-stocks__right {
  left: 0;
  top: -1.5em;
  margin-bottom: 0em;

  &::before {
    content: " ";
    height: 0;
    position: absolute;
    left: 2.6em;
    top: 5em;
    width: 3.6em;
    z-index: 0;
    border-top: 1px solid #ccc;
  }

  &::after {
    left: 2em;
    top: 6.9em;
  }
}

/* Add arrows to the left container (pointing right) */
.c-stocks__left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 6.5em;
  width: 4.6em;
  z-index: 0;
  left: 4.6em;
  border-top: 1px solid #ccc;
}

/* The actual content */
.c-stocks__content {
  background-color: white;
  position: relative;
  margin-left: 4em;
  width: 100%;
  height: 100%;
  border-radius: 0.3em;
  box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.16);
  overflow: hidden;

  > div {
    height: inherit;
  }

  &.beef {
    border-right: 0.5em solid #0d8c49;
  }

  &.deer {
    border-right: 0.5em solid #a12041;
  }

  &.sheep {
    border-right: 0.5em solid #6ab445;
  }

  .c-stocks__type-detail {
    width: 100%;
    height: 100%;
    padding: 0 2em;

    .c-stocks__type-header {
      .c-stocks__species {
        display: inline-block;
        flex: 1;
        font-size: 1.5em;
        vertical-align: middle;
      }

      .c-stocks__type-container {
        text-align: right;
        display: inline-block;

        .c-stocks__payment-type {
          font-family: "ArcherSSm-Bold", "Roboto", "sans-serif";
          display: inline-block;
          background: #fff;
          font-size: 0.6em;
          border-radius: 1em;
          padding: 0 1em;
          margin: 0 1em 0 0.05em;
          color: #333333;
          border: 1px solid #333333;
          vertical-align: middle;
        }

        .c-stocks__species-icon {
          display: inline-block;
          vertical-align: middle;

          > img {
            width: 2em;
            height: auto;
          }
        }
      }
    }
  }
}

.c-stocks__dashed-bottom-border {
  border-bottom: 1px dashed #ccc;
  margin: 0.5em 0;
  padding: 0.5em 0;
  display: flex;
  flex-direction: row;
}

.c-stocks__attribute-container {
  font-family: "Graphik-Medium", "Roboto", "sans-serif";
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0.5em 0;

  .c-stocks__attribute {
    padding: 0 0.5em 0.75em 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &.primary {
      font-size: 0.8em;
      padding: 0.25em 0.5em 0.75em 0;

      .c-stocks__attribute-subheading {
        font-family: "Graphik", "Roboto", "sans-serif";
        font-size: 0.7em;
        color: #bababa;
      }
    }

    &.primary-attribute-column-1 {
      width: 15%;
    }

    &.primary-attribute-column-2 {
      width: 25%;
    }

    &.primary-attribute-column-3 {
      width: 20%;
    }

    &.primary-attribute-column-4 {
      width: 40%;
    }

    &.primary-attribute-column-5 {
      width: 60%;
    }
  }
}

.c-stocks__load-error-container {
  max-width: 40em;
  height: 87vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.c-stocks__empty-stocks-container {
  width: 100%;
  max-width: 40em;
  min-width: 31em;
  height: 72vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.c-stocks__empty-stocks-text {
  font-family: "Graphik";
  font-weight: 100;
  font-size: 1.1em;
  text-align: center;
  margin-top: 2em;
}

@media all and (max-width: 1080px) {
  .c-stocks__content-container {
    max-width: 31em;
  }
}

@media all and (min-width: 1920px) {
  .c-stocks__load-error-container {
    max-width: 100%;
  }
  .c-stocks__empty-stocks-container {
    max-width: 100%;
  }
}
