.c-final-kill-sheet__header {
  font-size: 1.2em;
  margin-bottom: -0.5em;
  margin-top: 1em;
}

.c-final-kill-sheet-details__content-container {
  height: 110vh;
  overflow: hidden;
  width: 100%;
  min-width: 40em;

  .c-final-kill-sheet-details__header {
    background: #427b5d;
    margin-top: 1.5em;
    padding-left: 0.55em;
    padding-top:  0.55em;
    padding-bottom:  0.55em;
    border-radius: 0.3em 0 0 0;

    * {
      color: #fff;
    }
    
    .c-final-kill-sheet-details__hover-view {
      margin-top: -2.9em; //Changed from top: 3.2em to margin-top negative to fix position of View PDF button
      //position: absolute;
      right: 0;
      width: calc(100% - 52.5em);
      background: #fff;
      border-radius: 0;
      box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.13);
      width: auto;
      padding-left: 1em;
      padding-right: 12px;
      display: inline-block;
      white-space: nowrap;
      float: right;
      span {
        font-family: "Graphik-SemiBold", "Roboto", "sans-serif";
        color: #427b5d;
        font-size: 1.2em;
        font-weight: 600;
        text-align: left;
        display: inline-block;
      }
    }
    .buttonEdgeLeft {
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
    }
    .buttonEdgeRight {
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
    }


    .c-final-kill-sheet-details__view {
      // position: absolute;
      margin-top: -3.2em; //Changed from top: 3.2em to margin-top negative to fix position of View PDF button
      right: 0;
      width: calc(100% - 52.5em);
      background: #fff;
      border-radius: 0;
      box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.13);
      min-width: 9em;
      padding-left: 2em;
      display: inline-block;
      white-space: nowrap;
      float: right;
      span {
        font-family: "Graphik-SemiBold", "Roboto", "sans-serif";
        color: #427b5d;
        font-size: 1.2em;
        font-weight: 600;
        text-align: left;
        display: inline-block;
        letter-spacing: 0.6px;
      }
    }

    .c-final-kill-sheet-details__header-content {
      max-width: 44em;
      position: relative;

      .c-final-kill-sheet-details__subject-container {
        width: 50%;
        display: inline-block;
        line-height: 2em;

        .c-final-kill-sheet-details__species-icon {
          > img {
            width: 1.25em;
            height: 1.25em;
            margin: 0 0.5em 0 0.75em;
            vertical-align: middle;
          }
        }

        .c-final-kill-sheet-details__species-text {
          letter-spacing: 1.5px;
          padding-right: 0.55em;
          vertical-align: middle;
          font-size: 1.2em;
        }

        .c-final-kill-sheet-details__categories {
          font-family: "ArcherSSm-Bold", "Roboto", "sans-serif";

          .c-final-kill-sheet-details__category {
            border: 1px solid #fff;
            font-size: 0.75rem;
            border-radius: 1em;
            padding: 0 0.55em;
            margin-right: 0.5em;
            background: #427b5d;
          }
        }
      }

      .c-final-kill-sheet-details__action-container {
        width: 50%;
        display: inline-block;
        text-align: right;
        position:absolute;

        .c-final-kill-sheet-details__date {
          margin: 0 0.25em;
          display: inline-block;
          vertical-align: text-bottom;
          font-family: "Graphik-Medium", "Roboto", "sans-serif";
          font-size: 1em;
          font-weight: 100;
          vertical-align: middle;
        }

        .c-final-kill-sheet-details__download {
          margin: 0.1em 0.25em;
          display: inline-block;
          vertical-align: top;
          cursor: pointer;
        }
      }
    }
  }

  .c-final-kill-sheet-details__content {
    background: #fff;
    height: 75%;
    border-radius: 0 0 0 0.3em;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    -webkit-box-shadow: inset 0 20px 20px -10px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: inset 0 20px 20px -10px rgba(0, 0, 0, 0.16);
    box-shadow: inset 0 20px 20px -10px rgba(0, 0, 0, 0.16);

    .c-final-kill-sheet-details__content-container {
      max-width: 45em;
      padding: 2em 0;
      height: 75vh;
      overflow: hidden;
      display: table;

      .c-final-kill-sheet__dashed-bottom-border {
        border-bottom: 1px dashed rgba(0, 0, 0, 0.16);
        margin: 0.5em 2em;
      }

      .c-final-kill-sheet__bottom-space {
        width: 100%;
        height: 0px;
        background-color: transparent;
      }

      .c-final-kill-sheet-details__primary-attributes-container {
        display: inline-block;
        width: 100%;
        padding: 0 2em;

        .c-final-kill-sheet-details__primary-attribute {
          padding: 0 0.5em 0.5em 0;
          font-size: 1.3em;
          width: 37%;
          display: inline-block;

          .c-final-kill-sheet-details__attribute-subheading {
            font-family: "Graphik", "Roboto", "sans-serif";
            font-size: 0.6em;
            color: #bababa;
          }
        }
      }

      .c-final-kill-sheet-details__secondary-attributes-container {
        display: inline-block;
        margin: 1em 2em 0.5em 2em;
        font-family: "Graphik-Medium", "Roboto", "sans-serif";
        font-size: 1em;
        width: 100%;

        .c-final-kill-sheet-details__secondary-attribute {
          width: 31%;
          padding: 0 0.5em 0.5em 0;
          display: inline-block;
        }

        .c-final-kill-sheet-details__row-attribute {
          width: 100%;
          padding: 0 2.5em 0.5em 0;
          display: inline-block;
        }

        .c-final-kill-sheet-details__attribute-subheading {
          font-family: "Graphik", "Roboto", "sans-serif";
          font-size: 0.75em;
          color: #bababa;
        }
      }

      .c-final-kill-sheet-details__hit-rate-container {
        margin: 1.5em 2em;
      }
    }
  }
}

#c-final-kill-sheet-details__download-menu {
  * {
    font-family: "Graphik";
    font-size: 14px;
    font-weight: bold;
    margin-top: 7px;
    padding-top: 0px;
    letter-spacing: 0.35px;
  }
}

@media all and (max-width: 1920px) {
  .c-final-kill-sheet-details__content-container {
    .c-final-kill-sheet-details__header {
      .c-final-kill-sheet-details__view {
        width: 13em;
      }
    }
  }
}

/* Media queries - Responsive timeline on screens less than 960px wide */
@media all and (max-width: 960px) {
  .c-final-kill-sheet-details__content-container {
    min-width: 31em;
    .c-final-kill-sheet-details__content {
      .c-final-kill-sheet-details__secondary-attributes-container {
        width: 90%;
      }
    }
  }
  .c-final-kill-sheet__bottom-space {
    height: 50px !important;
  }
}

/* IE Only - on screens less than 960px wide */
@media all and (max-width: 960px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .c-final-kill-sheet-details__content-container {
    .c-final-kill-sheet-details__content {
      .c-final-kill-sheet-details__secondary-attributes-container,
      .c-final-kill-sheet-details__hit-rate-container,
      .c-final-kill-sheet-details__health-report-container,
      .c-final-kill-sheet-details__grades-container {
        width: 75% !important;
      }
    }
    .c-final-kill-sheet__dashed-bottom-border {
      width: 65%;
    }
  }
}

/* Media queries - Responsive timeline on screens between 961px and 1280px wide */
@media all and (min-width: 961px) and (max-width: 1280px) {
  .c-final-kill-sheet-details__content-container {
    min-width: 37em;
  }
}

@media all and (max-height: 768px) {
  .c-final-kill-sheet-details__content-container {
    height: 75vh;
  }
}
