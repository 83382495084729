.p-my-stock__container {
  background: rgba(0, 0, 0, 0.02);
  overflow-y: hidden;
  height: calc(100vh - 3.25em);

  .p-my-stock__tabs {
    display: inline-flex;
    margin: 1em 2em 0 5em;

    [aria-selected="false"] {
      color: #ccc !important;
    }

    .p-my-stock__tab {
      font-family: "Graphik-SemiBold", "Roboto", sans-serif;
      color: #000;
      font-size: 1.2em;
      padding: 0.5em 0;
      text-align: left;
      margin-right: 1em;
      min-width: fit-content;

      > span {
        > span {
          padding: 0;
        }
      }
    }

    > div {
        > span {
          height: 0.25em;
          background-color: #427b5d;
      }
    }
  }
}

@media all and (min-width: 1920px) {
  .p-my-stock__container {
    display: flex;
    flex-direction: row;
    overflow-y: hidden;
  }
  .p-my-stock__tablet-view-container {
    display: none;
    overflow-y: hidden;
  }
  .p-my-stock__desktop-view-container {
    display: grid;
  }
}

@media all and (max-width: 1919px) {
  .p-my-stock__tablet-view-container {
    display: grid;
    overflow-y: hidden;
  }
  .p-my-stock__desktop-view-container {
    display: none;
  }
}
