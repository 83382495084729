.c-invoices__container {
  display: inline-block;
  width: 100%;
  height: calc(100vh - 8.69em);
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  overflow: "-moz-scrollbars-none";
  min-width: 720px;
  padding-bottom: 2.8em;
}

.c-invoices__load-error-container {
  height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.c-invoices__empty-stocks-container {
  width: 100%;
  height: 72vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.c-invoices__empty-stocks-text {
  font-family: "Graphik";
  font-weight: 100;
  font-size: 1.1em;
  text-align: center;
  margin-top: 2em;
}

.c-outstandingInvoiceSummaryLine__div {
  background-color: white;
  border-radius: 8px;
  min-width: 70vw;
  margin-left: 5em;
  margin-right: 5em;
  margin-bottom: 0.6em;

  > div {
    box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.13);

    .c-outstandingInvoiceSummaryLine__item {
      &.grid-container {
        padding: 0.25em 0;

        > div {
          padding: 1em 0;
        }

        > div:nth-child(5) {
          display: none;
        }

        > div:nth-child(6) {
          display: none;
        }
      }
    }
  }

  > .expanded {
    border-left: 7px solid #427b5d;

    #c-outstandingInvoiceDetailLine__panel-summary {
      > div {
        margin: 12px 0;

        > div {
          > div:nth-child(4) {
            padding-right: 0.5em;
          }

          > div:nth-child(5) {
            display: block;

            &.border-filler-container {
              max-width: 3.5em;
            }
          }

          > div:nth-child(6) {
            display: block;
            padding: 0;

            .bottom-border {
              border-bottom: 1px solid rgba(51, 51, 51, 0.06);
            }
          }
        }
      }
    }

    .c-outstandingInvoiceSummaryLine__item {
      &.icon {
        background-color: #427b5d;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        display: inline-block;
        color: white;
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
      }
    }

    .c-outstandingInvoiceDetailLine__div {
      .c-outstandingInvoiceDetailLine__item-subheading {
        &.filler-container {
          max-width: 3em;
        }
      }
    }

    > div:nth-of-type(1) {
      padding-left: 0.85em;
      padding-right: 0em;
    }
  }

  .c-outstandingInvoiceSummaryLine__item {
    font-size: 1em;
    font-family: "Graphik", "Roboto", "sans-serif";

    &.icon-container {
      max-width: 3.5em;
      text-align: center;
    }

    &.icon {
      color: #427b5d;
      border: 2px solid #427b5d;
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }

    &.capitalise {
      text-transform: uppercase;
    }

    &.right {
      text-align: right;
      font-family: "Graphik-Semibold", "Roboto", "sans-serif";
    }

    .c-outstandingInvoiceSummaryLine__item-subheading {
      font-family: "Graphik-Medium", "Roboto", "sans-serif";
      font-size: 0.7em;
      color: #bababa;
      margin-top: 0.25em;
      letter-spacing: 0.05em;
    }
  }
}

.c-outstandingInvoiceDetailLine__div {
  background-color: white;
  border-radius: 8px;
  min-width: 64vw;
  width: 100%;

  &.invoices {
    padding-right: 0.75em;

    > .c-outstandingInvoiceDetailLine__div {
      > div {
        > div:nth-child(2) {
          max-width: 12.333333%;
          flex-basis: 12.333333%;
        }

        > div:nth-child(3) {
          max-width: 9.333333%;
          flex-basis: 9.333333%;
        }

        > div:nth-child(4) {
          max-width: 14.666667%;
          flex-basis: 14.666667%;
        }

        > div:nth-child(5) {
          max-width: 6.333333%;
          flex-basis: 6.333333%;
        }

        > div:nth-child(6) {
          max-width: 8.333333%;
          flex-basis: 8.333333%;
        }

        > div:nth-child(7) {
          max-width: 21.666667%;
          flex-basis: 21.666667%;
        }

        > div:nth-child(8) {
          max-width: 11.666667%;
          flex-basis: 11.666667%;
        }

        > div:nth-child(9) {
          text-align: right;
        }
      }
    }
  }

  > div {
    padding: 0px;
  }

  > div {
    > div {
      > div {
        padding: 0.25em 0em !important;
      }
    }
  }

  .c-outstandingInvoiceDetailLine__item-subheading {
    &.filler-container {
      max-width: 3em;
    }
  }

  .c-outstandingInvoiceDetailLine__item {
    font-size: 0.9em;
    font-family: "Graphik", "Roboto", "sans-serif";

    &.no-overflow {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    > svg {
      color: #427b5d;
    }

    > svg + span {
      color: #427b5d;
      position: relative;
      left: 0.7em;
      bottom: 0.3em;
    }

    &.filler-container {
      max-width: 3.5em;
    }

    &.capitalise {
      text-transform: uppercase;
    }

    &.documentType {
      font-family: "Graphik-Semibold", "Roboto", "sans-serif";
      color: white;
      text-align: center;
      font-size: 0.5em;
      width: 10em;
      position: relative;
      top: 0.5em;

      &.invoice {
        background-color: rgb(66, 123, 93);
        position: relative;
        top: 0.75em;
      }

      &.journal {
        background-color: rgb(176, 181, 105);
        position: relative;
        top: 0.75em;
      }

      &.credit-memo {
        background-color: rgb(224, 84, 74);
        position: relative;
        top: 0.75em;
      }
    }

    &.icon {
      > svg {
        color: rgb(66, 123, 93);
      }
    }

    &.negative {
      color: rgb(224, 84, 74);
    }

    &.right {
      text-align: right;
    }

    &.download-pdf {
      font-size: 1em;
      width: fit-content;
      cursor: pointer;
    }

    &.no-download-pdf {
      > svg {
        color: #bababa;
      }
      > svg + span {
        font-size: 1.1em;
        color: #bababa;
      }
    }

    .c-outstandingInvoiceDetailLine__item-subheading {
      font-family: "Graphik-Medium", "Roboto", "sans-serif";
      font-size: 0.8em;
      color: #bababa;
      margin-top: 0;
      letter-spacing: 0.05em;
      padding-left: 0.2em;
    }
  }
}

/* Media queries - Responsive timeline on screens up to 1440px wide */
@media all and (max-width: 1440px) {
  .c-outstandingInvoiceSummaryLine__div {
    > .expanded {
      border-left: 7px solid #427b5d;

      #c-outstandingInvoiceDetailLine__panel-summary {
        > div {
          > div {
            > div:nth-child(5) {
              display: block;

              &.border-filler-container {
                max-width: 3.5em;
              }
            }

            > div:nth-child(6) {
              display: block;
              padding: 0 1em 0 0;
            }
          }
        }
      }
    }
  }

  .c-outstandingInvoiceDetailLine__div {
    &.invoices {
      > .c-outstandingInvoiceDetailLine__div {
        > div {
          > div:nth-child(2) {
            max-width: 13.666667%;
            flex-basis: 13.666667%;
          }

          > div:nth-child(3) {
            max-width: 10.333333%;
            flex-basis: 10.333333%;
          }

          > div:nth-child(4) {
            max-width: 31%;
            flex-basis: 31%;
          }

          > div:nth-child(5) {
            max-width: 9.333333%;
            flex-basis: 9.333333%;
          }

          > div:nth-child(7) {
            max-width: 8.333333%;
            flex-basis: 8.333333%;
          }

          > div:nth-child(8) {
            display: none;
          }
        }
      }
    }
  }
}

/* Media queries - Responsive timeline on screens between 1281px and 14400px wide */
@media all and (min-width: 1281px) and (max-width: 1440px) {
  .c-outstandingInvoiceSummaryLine__div {
    .c-outstandingInvoiceSummaryLine__item {
      font-size: 1em;

      &.icon {
        height: 24px;
        width: 24px;
      }

      &.icon-container {
        padding-top: 1.25em;
      }

      &.right {
        margin-right: 0.5em;
      }
    }

    > .expanded {
      .c-outstandingInvoiceSummaryLine__item {
        &.icon {
          height: 24px;
          width: 24px;
        }
      }
    }
  }

  .c-outstandingInvoiceDetailLine__div {
    .c-outstandingInvoiceDetailLine__collapsible-icon-text {
      display: none;
    }

    .c-outstandingInvoiceDetailLine__item {
      font-size: 0.9em;

      &.collapsible-item-column {
        display: none;
      }
    }

    .c-outstandingInvoiceDetailLine__item-subheading {
      &.filler-container {
        margin-left: 0.65em !important;
        max-width: 2.35em !important;
      }
    }
  }
}

/* Media queries - Responsive timeline on screens between 1120px and 1280px wide */
@media all and (min-width: 1120px) and (max-width: 1280px) {
  .c-outstandingInvoiceSummaryLine__div {
    .c-outstandingInvoiceSummaryLine__item {
      font-size: 1em;

      &.icon {
        height: 24px;
        width: 24px;
      }

      &.icon-container {
        padding-top: 1.25em;
      }

      &.right {
        padding-right: 0.75em;
      }
    }

    > .expanded {
      .c-outstandingInvoiceSummaryLine__item {
        &.icon {
          height: 24px;
          width: 24px;
        }
      }
    }
  }

  .c-outstandingInvoiceDetailLine__div {
    .c-outstandingInvoiceDetailLine__collapsible-icon-text {
      display: none;
    }

    .c-outstandingInvoiceDetailLine__item {
      font-size: 0.8em;

      &.filler-container {
        max-width: 2.75em;
        margin-left: 1em;
      }

      &.collapsible-item-column {
        display: none;
      }
    }

    .c-outstandingInvoiceDetailLine__item-subheading {
      &.filler-container {
        max-width: 2.75em !important;
        margin-left: 0.2em !important;
      }
    }
  }
}

/* Media queries - Responsive timeline on screens up to 1119px wide */
@media all and (max-width: 1119px) {
  .c-outstandingInvoiceDetailLine__div {
    &.invoices {
      > .c-outstandingInvoiceDetailLine__div {
        > div {
          > div:nth-child(2) {
            max-width: 16.666667% !important;
            flex-basis: 16.666667% !important;
          }

          > div:nth-child(3) {
            display: none;
          }
        }
      }
    }
  }

  .c-outstandingInvoiceSummaryLine__div {
    .c-outstandingInvoiceSummaryLine__item {
      font-size: 0.9em;

      &.icon {
        height: 24px;
        width: 24px;
      }

      &.icon-container {
        padding-top: 1.25em;
      }

      &.right {
        margin-right: 0.75em;
      }
    }

    > .expanded {
      .c-outstandingInvoiceSummaryLine__item {
        &.icon {
          height: 24px;
          width: 24px;
        }
      }
    }
  }

  .c-outstandingInvoiceDetailLine__div {
    .c-outstandingInvoiceDetailLine__collapsible-icon-text {
      display: none;
    }

    .c-outstandingInvoiceDetailLine__item {
      font-size: 0.75em;

      &.filler-container {
        max-width: 3.25em !important;
        margin-left: 0 !important;
      }

      &.collapsible-item-column {
        display: none;
      }
    }
  }
}

/* Media queries - Responsive timeline on screens between 1025px and 1119px wide */
@media all and (min-width: 1025px) and (max-width: 1119px) {
  .c-outstandingInvoiceDetailLine__div {
    .c-outstandingInvoiceDetailLine__collapsible-icon-text {
      display: none;
    }

    &.invoices {
      > .c-outstandingInvoiceDetailLine__div {
        > div {
          > div:nth-child(4) {
            max-width: 38%;
            flex-basis: 38%;
          }
        }
      }
    }
  }

  .c-outstandingInvoiceSummaryLine__div {
    .c-outstandingInvoiceDetailLine__item-subheading {
      &.filler-container {
        max-width: 2.6em !important;
        margin-left: -0.3em !important;
      }
    }
  }
}

/* Media queries - Responsive timeline on screens up to 1024px wide */
@media all and (max-width: 1024px) {
  .p-payments__container {
    .p-payments__tabs {
      margin: 1em 0 1em 2em !important;
    }

    .c-outstandingInvoiceSummaryLine__div {
      margin-left: 2em;
      margin-right: 2em;

      .c-outstandingInvoiceDetailLine__item-subheading {
        &.filler-container {
          margin-left: -0.3em !important;
        }
      }
    }

    .c-outstandingInvoiceDetailLine__div {
      &.invoices {
        > .c-outstandingInvoiceDetailLine__div {
          > div {
            > div:nth-child(4) {
              max-width: 36%;
              flex-basis: 36%;
            }
          }
        }
      }
    }
  }
}

/* Media queries - Responsive timeline on screens less than 960px wide */
@media all and (max-width: 959px) {
  .p-payments__container {
    .c-outstandingInvoiceSummaryLine__div {
      .c-outstandingInvoiceSummaryLine__item {
        &.right {
          margin-right: 1.25em;
        }
      }

      .c-outstandingInvoiceDetailLine__item-subheading {
        &.filler-container {
          max-width: 2.75em !important;
          margin-left: -0.5em !important;
        }
      }
    }
  }
}
