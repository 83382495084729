.c-dashboard__tile {
  box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.16) !important;
  height: 100%;
  min-height: 138px;


  &-analytics {
    display: initial;
    padding-bottom: 5em!important;      
    &-tile {
      padding-bottom: 0.5em;      
      padding-top: 1.8em;
      padding-left: 1em !important;
      padding-right: 1em !important;
    }
  }

  &-analytics-img {
    width: 100%;
    padding-left: 0.9em;
    padding-right: 0.9em;
    @media (max-width: 1599px) and (min-width: 1280px){
      font-size: 0.6em;
      padding-left: 1.9em;
      padding-right: 1.9em;
      height: 80%;
    }

    @media (max-width: 1279px) and (min-width: 769px){
      padding-left: 0.9em;
      padding-right: 0.9em;
      height: 80%;
    }

    @media (max-width: 768px) {
      padding-left: 0.9em;
      padding-right: 0.9em;
      height: 100%;
    }
  }

  &-analytics-view-all {
    display: inline-flex;
    float: right;
    font-size: 0.5em;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    text-align: center;
    border: solid 1px;
    white-space: pre;
    color: #427b5d;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    margin-top: 1.5em;
    padding-right: 3em;
    padding-left: 3em;
    margin-right: 2em;


    @media all and (max-width: 768px) {
    font-size: 0.4em;
    margin-top: 2.2em;
    padding-right: 1em;
    padding-left: 1em;
    }
    @media (max-width: 1599px) and (min-width: 1280px){
      padding-right: 2.5em;
      padding-left: 2.5em;
      margin-top: 1.7em;
      margin-right: 2.5em;
    }

    @media all and (max-width: 898px) {
      font-size: 0.4em;
    margin-top: 2.2em;
    padding-right: 1em;
    padding-left: 1em;
    }

    @media all and (max-width: 1024px) {
      font-size: 0.4em;
      margin-top: 1.4em;
      padding-right: 3em;
      padding-left: 3em;
      margin-right: 2em;
    }

    // @media all and (max-width: 1366px) {
    //   font-size: 0.4em;
    //   margin-top: 1.5em;
    //   padding-right: 2em;
    //   padding-left: 2em;
    //   margin-right: 2.5em;
    // }
    @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
      font-size: 0.4em;
      margin-top: 1.5em;
      padding-right: 2em;
      padding-left: 2em;
      margin-right: 2.5em;
    }
  }

  &-header-1-inline {
    font-size: 0.9em;
    display: inline-flex;
    padding-top: 0.9em;
    margin-left: -1em;
    @media (max-width: 1599px) and (min-width: 1280px){
      font-size: 1em;
      margin-left: 0em;
      
    }
    @media (max-width: 1279px) and (min-width: 769px){
      font-size: 1em;
      padding-top: 0.4em;
      margin-left: 0em;
    }

    @media (max-width: 768px) {
      font-size: 0.7em;
    }
    
  }

  &-hyperlink {
    color: #222222;
    text-decoration: none !important;
  }

  &-hyperlink a:hover {
    text-decoration: none !important;
  }

  > div {
    padding: 1.8em;
    border-radius: 4px;
  }

  @media all and (min-width: 1280px) and (max-width: 1599px) {
    > div {
      padding: 1.1em;
    }
  }

  @media all and (max-width: 1599px) {
    &-killsheet,
    &-forecast,
    &-booking {
      &-view-all {
        margin-top: 0 !important;
        margin-right: 0 !important;
      }
    }
  }

  @media all and (min-width: 1025px) {
    &-forecast,
    &-booking {
      padding-right: 3em !important;
    }
  }

  @media all and (min-width: 961px) and (max-width: 1024px) {
    &-forecast,
    &-booking {
      padding-right: 2em !important;
    }
  }

  @media all and (max-width: 1279px) {
    > div {
      padding: 1em;
    }
  }

  &-dashboard {
    height: calc(65vh - 8em);
  }

  &-farm-assure {
    height: 100%;
    color: white;
    overflow: hidden;
    padding: 0em !important;

    &.green {
      background-color: #67a222 !important;
    }

    &.amber {
      background-color: #d9ab11 !important;
    }

    &.red {
      background-color: #c33333 !important;
    }

    &.expired {
      .c-dashboard__tile-farm-assure-type {
        font-size: 1.5em;
        > svg {
          font-size: 0.55em;
          vertical-align: baseline;
        }
      }
    }

    &-subject-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding-bottom: 1.5em;
      padding-top: 1.5em;
    }

    &-species-icon {
      display: ruby;

      @media all and (max-width: 898px) {
        padding-right: 0.5em;
      }

      > svg {
        display: inline-block;
      }

      > span {
        font-family: "ArcherSSm-Bold", "Roboto", sans-serif;
        font-size: 0.8em;
        display: inline-block;
        writing-mode: vertical-rl;
        text-orientation: mixed;
        vertical-align: baseline;
        text-transform: uppercase;
      }

      /* Safari ONLY - NOT SURE HOW LONG THIS WILL LAST FOR - IDEALLY NEED A MORE STANDARD APPROACH FOR ALL BROWSERS */
      @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) {
          display: -webkit-flex;
          margin-right: 0em;
          > span {
            position: relative;
          }
          &-deer {
            + span {
              top: 4.1em;
            }
          }
          &-sheep {
            + span {
              top: 2.8em;
            }
          }
          &-beef {
            + span {
              top: 2.4em;
            }
          }
        }
      }

      &-deer {
        width: 4.3em !important;
        height: 4.3em !important;
        margin-top: 0.3em;
        margin-left: -1.4em;
        margin-right: -0.8em;
        margin-bottom: 0em;

        -webkit-transform: scaleX(-1);
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        transform: scaleX(-1);

        filter: FlipH;
        -ms-filter: "FlipH";
      }

      &-beef {
        width: 4.2em !important;
        height: 4.2em !important;
        margin-left: -2.3em;
        margin-right: 0.2em;
        margin-bottom: -0.7em;
        margin-top: 0.8em;

        -webkit-transform: scaleX(-1);
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        transform: scaleX(-1);

        filter: FlipH;
        -ms-filter: "FlipH";
      }

      &-sheep {
        width: 4em !important;
        height: 4em !important;
        margin-top: 0.4em;
        margin-left: -1.6em;
        margin-right: -0.3em;
        margin-bottom: -0.3em;
      }

      @media all and (min-width: 1600px) {
        &-deer {
          &.single-tile {
            margin-left: 2.9em;
          }
        }
        &-beef {
          &.single-tile {
            margin-left: 1.8em;
          }
        }
        &-sheep {
          &.single-tile {
            margin-left: 2.2em;
          }
        }
      }

      @media all and (min-width: 1025px) and (max-width: 1279px) {
        &-deer {
          margin-left: 2.9em;
        }
        &-beef {
          margin-left: 1.8em;
        }
        &-sheep {
          margin-left: 2.2em;
        }
      }
    }

    &-species-expiry {
      margin-left: auto;
      margin-right: auto;
      &-text {
        font-family: "Graphik", "Roboto", sans-serif;
        font-size: 0.7em;
        display: block;
        text-transform: uppercase;
      }
      &-date {
        font-size: 1.1em;
        display: block;
        text-transform: uppercase;
        border-bottom: 2px solid;
        padding-bottom: 0.2em;
        margin-bottom: 0.2em;
      }

      &-soon-text {
        display: block;
        font-family: "Graphik";
        font-size: 0.55em;
        > span {
          display: block;
        }
      }
    }

    &-type {
      font-size: 0.7em;
      letter-spacing: 0.05em;
      text-transform: uppercase;

      > svg {
        font-size: 1.2em;
        vertical-align: text-bottom;
        margin-left: 0.5em;
      }
    }

    @media all and (max-width: 898px) {
      &-species-expiry-date {
        font-size: 1em;
      }

      &-species-expiry-soon-text {
        font-size: 0.5em;
      }

      &-type {
        font-size: 0.6em;
      }
    }
  }

  &-link {
    font-size: 0.6em;
    font-family: "Graphik-Medium";
    color: #bababa;
    float: right;
    margin-top: 1.2em;
    margin-right: 1.5em;
  }

  &-header-1 {
    font-size: 1.3em;
  }


  /* Booking & Forecast */
  &-booking-info,
  &-forecast-info {
    padding: 0.6em 0 0;
  }

  &-booking-icon-container,
  &-forecast-icon-container {
    display: inline-block;
    height: 3em;
  }

  &-booking-view-all,
  &-forecast-view-all {
    font-size: 0.5em;
    font-family: "Graphik-Medium";
    color: #cccccc;
    float: right;
    margin-top: -0.5em;
    margin-right: -0.5em;
  }

  &-booking-next,
  &-forecast-next,
  &-booking-quantity,
  &-forecast-quantity {
    display: inline-block;
  }

  &-booking-quantity,
  &-forecast-quantity {
    margin-left: 1.2em;
  }

  &-booking-programme,
  &-forecast-programme {
    display: block;
    margin-left: 2.9em;
  }

  &-booking-programme-number,
  &-forecast-programme-number {
    display: flex;
    max-height: 38px;
  }

  &-booking-programme-number-ellipsis,
  &-forecast-programme-number-ellipsis {
    white-space: pre-wrap;
    overflow: hidden;
    width: 100%;
  }

  &-booking-next-text,
  &-booking-quantity-text,
  &-booking-programme-text,
  &-forecast-next-text,
  &-forecast-quantity-text,
  &-forecast-programme-text {
    display: block;
    font-size: 0.7em;
  }

  &-booking-next-number,
  &-booking-quantity-number,
  &-booking-programme-number,
  &-forecast-next-number,
  &-forecast-quantity-number,
  &-forecast-programme-number {
    font-family: "Graphik-Medium";
    font-size: 1.8em;
    &.deer {
      color: #a12041;
    }
    &.beef {
      color: #0d8c49;
    }
    &.sheep {
      color: #6ab445;
    }
  }

  &-booking-programme-name,
  &-forecast-programme-name {
    font-size: 0.75em;
  }

  &-booking-icon,
  &-forecast-icon {
    width: 3.5em;
    margin-left: -0.65em;
    margin-bottom: -1.2em;
    margin-top: 0.5em;
  }

  @media all and (min-width: 1600px) {
    &-booking {
      &.no-forecasts {
        .c-dashboard__tile-booking-info {
          display: flex;
          flex-direction: row;
        }
        .c-dashboard__tile-booking-next {
          width: 14em;
        }
        .c-dashboard__tile-booking-programme-text {
          margin-bottom: 8px;
        }
        .c-dashboard__tile-booking-programme {
          position: relative;
          width: 100%;
        }
      }
    }
  }

  @media all and (min-width: 1281px) and (max-width: 1599px) {
    &-booking-quantity,
    &-forecast-quantity {
      display: block;
      margin-left: 0.4em !important;
    }

    &-booking-programme,
    &-forecast-programme {
      display: block;
      margin-left: 0.4em !important;
    }

    &-booking-next,
    &-forecast-next {
      display: block;
      margin-left: 0.4em !important;
    }

    &-killsheet-text {
      font-size: 1em;
      margin-left: 3em !important;
    }
  }

  @media all and (max-width: 1024px) {
    &-booking-quantity,
    &-forecast-quantity {
      display: block;
      margin-left: 0.4em !important;
    }

    &-booking-programme,
    &-forecast-programme {
      display: block;
      margin-left: 0.4em !important;
    }

    &-booking-next,
    &-forecast-next {
      display: block;
      margin-left: 0.4em !important;
    }

    &-booking-programme-name,
    &-forecast-programme-name {
      font-size: 0.85em;
    }

    &-booking-programme-number,
    &-forecast-programme-number {
      font-size: 1.2em;
    }
  }

  /* iPad-Pro Portrait */
  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    &-booking-quantity,
    &-forecast-quantity {
      display: block;
      margin-left: 0.4em !important;
    }

    &-booking-programme,
    &-forecast-programme {
      display: block;
      margin-left: 0.4em !important;
    }

    &-booking-next,
    &-forecast-next {
      display: block;
      margin-left: 0.4em !important;
    }

    &-booking-programme-number,
    &-forecast-programme-number {
      font-size: 1.2em;
    }
  }

  @media all and (max-width: 1279px) {
    &-killsheet-text {
      margin-left: 3em !important;
    }
  }

  @media all and (max-width: 809px) {
    &-killsheet-text {
      font-size: 0.7em !important;
      margin-left: 4.25em !important;
    }
  }
  /* Error styling */
  &.error {
    .c-load-error__container {
      margin-bottom: 0em;
      padding-top: 0.4em;
    }
    .c-load-error__message {
      font-size: 1em;
      text-align: center;
    }
    @media all and (min-width: 1280px) and (max-width: 1599px) {
      .c-load-error__message {
        font-size: 0.8em;
      }
    }
    @media all and (max-width: 1279px) {
      .c-load-error__container {
        padding-top: 1.4em;
      }
    }
    @media all and (max-width: 959px) {
      .c-load-error__message {
        font-size: 0.8em;
      }
    }
    .c-load-error__button {
      button {
        background: #fff;
        border: 1px solid #427b5d;
        border-radius: 0;

        span > svg,
        span > div {
          font-family: "Graphik-SemiBold";
          color: #427b5d;
        }
      }
    }
  }

  /*SharePrice styling*/

  &-shareprice {
    background: #b0b569 !important;
    font-family: Graphik;
    font-stretch: normal;
    font-style: normal;
    text-align: left;
    color: #ffffff;
    flex-wrap: wrap;
    align-items: center;
    height: 100%;
    min-height: 156px;
    padding-bottom: 0.5em !important;
    padding: 0.5em !important;
    padding-top: 1.8em !important;
    padding-left: 1em !important;

    &-icon {
      padding-bottom: 0.5em;
      width: 1.7em;
    }

    &-iconflat {
      width: 1.2em;
    }

    &-leftdiv {
      display: inline-table;
      padding-right: 2em;
    }
    &-rightdiv {
      display: inline-block;
      padding-right: 0.5em;
      align-items: center;
    }

    &-title {
      font-size: 19px;
      font-weight: 600;
      line-height: 0.95;
      height: 19px;
      font-family: Graphik;
      letter-spacing: 0.63px;
    }
    &-price {
      height: 43px;
      font-size: 42px;
      font-weight: 500;
      letter-spacing: 2.65px;
    }
    &-trend {
      font-size: 12px;
      font-weight: 500;
      line-height: 1.58;
      letter-spacing: normal;
    }
    &-difference {
      font-size: 0.6em;
      font-weight: 500;
      line-height: 1.58;
      margin-left: -3.5em;
      padding-right: 3em;
    }
    &-updatedate {
      opacity: 0.5;
      font-size: 11px;
      font-weight: 500;
      line-height: 1.27;
      letter-spacing: normal;
      text-align: left;
      display: block;
    }
    &-iconcontainer {
      padding-left: 0em;
    }
    &-viewdetails {
      font-size: 0.55em;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: 0.11em;
      text-align: center;
      padding: 0.5em;
      width: 12.5em;
      margin-top: 1em;
      border: solid 1px;
      display: inline-block;
      white-space: pre;
    }

    @media all and (max-width: 768px) {
      &-leftdiv {
        padding-right: 0em !important;
      }

      &-rightdiv {
        padding-right: 0em !important;
      }

      &-viewdetails {
        padding-left: 2em;
        padding-right: 2em;
      }

      &-difference {
        font-size: 0.45em;
      }
    }

    @media all and (max-width: 898px) {
      padding-top: 1.9em !important;

      &-leftdiv {
        padding-right: 0em !important;
      }

      &-rightdiv {
        padding-right: 0em !important;
      }

      &-icon {
        margin-left: 1em;
        padding-bottom: 0.2em;
      }

      &-iconflat {
        width: 0.5em !important;
        padding-bottom: 0.5em;
        font-size: 1.3em;
      }

      &-iconcontainer {
        padding-left: 0.1em;
      }
      &-difference {
        padding-right: 0em;
      }

      &-difference {
        font-size: 0.45em;
      }
    }

    @media all and (max-width: 1024px) {
      padding-top: 1.9em !important;

      &-viewdetails {
        display: inline;
      }

      &-difference {
        padding-right: 0.5em;
      }

      &-leftdiv {
        padding-right: 0.5em;
      }
    }

    @media all and (max-width: 1366px) {
      padding-top: 1.9em !important;

      &-viewdetails {
        display: inline;
      }

      &-difference {
        padding-right: 0.5em;
      }

      &-leftdiv {
        padding-right: 1.4em;
      }
    }
  }

  /*Schedule styling*/

  &-schedule {
    &-tile {
      padding-bottom: 0.5em;      
      padding-top: 1.8em;
      padding-left: 1em !important;
      padding-right: 1em !important;
    }

    &-date {
      font-family: Graphik-Medium;
      font-size: 2.4em;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: #427b5d;
      padding-left: 0.2em;
      padding-right: 0.3em;
    }

    &-viewschedule {
      font-size: 0.7em;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      text-align: center;
      border: solid 1px;
      white-space: pre;
      color: #427b5d;
      padding: 0.8em 0.4em 0.8em 0.4em;
    }

    &-info {
      padding-top: 1em;
      overflow: hidden;
    }

    &-height {
      min-height: 200px !important;
    }

    &-icon {
      font-size: 1.7em !important;
      object-fit: contain;
      background-color: white;
      color: #427b5d !important;
    }

    &-icon-container {
      display: inline-block;
    }

    &-leftdiv {
      float: left;
    }

    &-rightdiv {
      overflow: hidden;
      padding-top: 0.8em;
      padding-bottom: 1em;
      text-align: center;
    }

    @media all and (max-width: 898px) {
      &-leftdiv {
        float: none;
        width: max-content;
      }
      &-viewschedule {
        display: block;
      }
      &-rightdiv {
        padding-bottom: 0em;
      }
      &-date {
        font-size: 2.3em;
        padding-left: 0.2em;
      }
    }

    @media all and (max-width: 1024px) {
      &-leftdiv {
        float: none;
        width: max-content;
      }
      &-viewschedule {
        display: block;
      }
      &-rightdiv {
        padding-bottom: 0em;
      }
    }

    @media all and (min-width: 1280px) and (max-width: 1599px) {
      &-tile {
        padding-top: 1em;
      }
      
      &-leftdiv {
        float: none;
        width: max-content;
      }      

      &-viewschedule {
        display: block;
      }

      &-rightdiv {
        padding-bottom: 0em;
      }
    }
    
  }

  /* Killsheet styling */

  &-killsheet-height {
    min-height: 200px !important;
  }

  &-shareprice-height {
    min-height: 200px !important;
    background: #b0b569 !important;
  }

  &-killsheet-info {
    padding: 0.6em 0 0;
  }

  &-killsheet-icon-container {
    display: inline-block;
    height: 3em;
  }

  &-killsheet-quantity {
    font-family: Graphik-Medium;
    font-size: 1.8em;
    color: #427b5d;
    margin-left: 0.2em;
  }

  &-killsheet-data-text,
  &-killsheet-quantity {
    display: inline-block;
  }

  &-killsheet-text {
    display: block;
    font-size: 1em;
    margin-left: 2.2em;
  }

  &-killsheet-text-small {
    display: block;
    font-size: 1em;
    margin-left: 1em;
  }

  &-killsheet-library_books-24px {
    font-size: 1.7em !important;
    object-fit: contain;
    background-color: white;
    color: #427b5d !important;
    margin-bottom: 0.15em;
  }

  &-killsheet-view-all {
    font-size: 0.5em;
    font-family: "Graphik-Medium";
    color: #cccccc;
    float: right;
    margin-top: -0.5em;
    margin-right: -0.5em;
  }

  /* Loading styling */
  &.loading {
    div[id^="c-progress__timeline"] {
      margin: 1em 0em 0em 0em;
    }
  }

  &.no-data {
    p {
      font-family: "Graphik";
      margin-bottom: 0;
    }
  }

  &.coming-soon {
    font-size: 1.3em;
    box-shadow: none !important;
    background: transparent;
    border: 2px dashed #cccccc;

    > div {
      padding: 1.5em;
    }

    @media (max-width: 1599px) and (min-width: 1281px) {
      > div {
        padding: 1.2em;
      }
    }

    @media (max-width: 1280px) {
      > div {
        padding: 1em;
      }
    }

    p {
      color: #cccccc;
      box-shadow: none !important;
      margin: 0;
      min-height: 0;
    }
    &-description {
      font-family: "Graphik";
      font-size: 0.9em;
    }
  }
}

.c-dashboard__tile-stream {
  #c-progress__timeline-stream {
    margin-top: -3em;
    margin-left: 0em;
  }

  &-tabs {
    display: flex;
    min-height: 32px !important;
    padding-bottom: 1em;
    > div {
        > span {
          height: 0.2em;
          background-color: #427b5d;
        }
      }

    /* IE10+ apply margin as padding works differently in IE. */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      &-view {
        margin-bottom: 0.75em;
      }
    }

    &-view {
      height: calc(95vh - 10em) !important;
      padding-bottom: 3em;

      .react-swipeable-view-container {
        > div[aria-hidden="true"] {
          height: 0px;
        }
      }

      .c-dashboard__tile {
        &.coming-soon {
          display: none;
        }

        &.no-data {
          .c-dashboard__tile-header-1 {
            display: none;
          }

          p {
            font-family: "Graphik";
            text-align: center;
          }
        }
      }

      .react-swipeable-view-container {
        > div {
          overflow: hidden !important;
        }
      }
    }

    button {
      .c-dashboard__tile-stream-tab-cs-label {
        display: none;
      }
    }
  }

  &-tab {
    font-family: "Graphik-SemiBold", "Roboto", sans-serif !important;
    font-size: 1em !important;
    text-align: left !important;
    min-width: auto !important;
    margin-right: 1em;
    color: #000 !important;
    min-height: 28px !important;
  }

  &-tab:disabled {
    font-size: 0.6em !important;
    color: #cccccc !important;
    padding: 0.2em;
    border: 1px dashed #cccccc;

    .c-dashboard__tile-stream-tab-cs-label {
      display: inline-block;
      color: #cccccc;
      padding-left: 0.2em;
      text-transform: none;
    }
  }

  @media all and (max-width: 810px) {
    &-tab {
      margin-right: 0.6em !important;
    }
  }

  @media all and (max-width: 767px) {
    &-tab {
      font-size: 0.9em !important;
    }
  }

  &-tab {
    > span {
      > span {
        padding: 0px;
      }
    }
  }
  .c-dashboard__tile {
    box-shadow: none !important;
    border: none;
  }

  &-social {
    &-card {
      display: flex;
      flex-direction: row;
      padding: 0.8em 0em 1.2em 0em;
    }

    &-image {
      object-fit: contain;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      margin-right: 1.2em;
      img {
        /* IE 8 */
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        /* IE 5-7 */
        filter: alpha(opacity=0);
        /* modern browsers */
        opacity: 0;
        height: 194px;
        width: 194px;
      }

      // &-wrapper {
      //   overflow: hidden;
      // }
    }

    @media all and (min-width: 899px) and (max-width: 1599px) {
      &-image {
        height: 174px;
        width: 174px;
        img {
          height: 174px;
          width: 174px;
        }
      }
    }

    @media all and (max-width: 898px) {
      &-image {
        height: 144px;
        width: 144px;
        margin-right: 0.8em;
        img {
          height: 144px;
          width: 144px;
        }
      }
    }

    &-info {
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;

      &-header {
        display: flex;
        flex-direction: row;
        height: fit-content;

        &-icon {
          padding-right: 0.5em;

          svg {
            font-size: 22px !important;
          }
        }

        &-latesticon {
          padding-right: 0.5em;

          svg {
            font-size: 35px !important;
          }
        }

        &-title {
          font-family: "ArcherSSm-Bold", "Roboto", sans-serif;
          font-size: 0.8em;
          padding-top: 0.1em;
        }
      }

      @media all and (max-width: 1599px) {
        &-header {
          padding-bottom: 0em;
        }
      }

      &-content {
        padding-bottom: 2em;
        width: 100%;

        &-text {
          font-family: "Graphik";
          font-size: 0.8em;
          white-space: pre-wrap;

          .LinesEllipsis-ellipsis {
            font-weight: bold;
          }
        }

        @media all and (max-width: 898px) {
          &-text {
            font-size: 0.7em;
          }
        }
      }

      @media all and (max-width: 1599px) {
        &-content {
          padding-bottom: 0em;
        }
      }

      &-footer {
        display: flex;
        align-items: flex-end;

        span {
          font-family: "Graphik";
          font-size: 0.7em;
          color: #cccccc;
        }

        @media all and (max-width: 1279px) {
          span {
            font-size: 0.6em;
            bottom: 2em;
          }
        }

        @media all and (max-width: 898px) {
          span {
            bottom: 3em;
          }
        }
      }
    }
  }

  &-news {
    &-card {
      flex-direction: row;
      padding: 0.8em 0em 0.2em 0em;
    }

    &-image {
      img {
        width: 100%;
        object-fit: contain;
        background: #cccccc;
      }
      &.no-thumbnail {
        display: none;
      }
    }

    &-upper {
      text-transform: uppercase;
    }

    @media all and (max-width: 1599px) {
      &-image {
        img {
          background: #cccccc;
        }
      }
      &-header {
        padding-bottom: 0em;
      }
    }

    @media all and (max-width: 1279px) {
      &-image {
        img {
          background: #cccccc;
        }
      }
    }

    @media all and (max-width: 898px) {
      &-image {
        img {
          background: #cccccc;
        }
      }
      &-title {
        a {
          font-size: 0.65em;
        }
      }
    }

    &-info {
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;

      &-header {
        display: flex;
        flex-direction: row;
        height: fit-content;

        &-icon {
          padding-right: 0.5em;

          svg {
            font-size: 40px !important;
          }
        }

        &-title {
          font-family: "ArcherSSm-Bold", "Roboto", sans-serif;
          font-size: 1.2em;
          padding-top: 0.1em;
          a {
            color: #222222;
            text-decoration: none;
          }
        }

        @media all and (max-width: 898px) {
          &-title {
            font-size: 0.75em;
          }
          &-icon {
            padding-right: 0.3em;
            svg {
              font-size: 24px !important;
            }
          }
        }
      }

      &-content {
        width: 100%;

        &.no-summary {
          padding-bottom: 6em;

          .c-dashboard__tile-stream-news-info-content-text {
            height: 0;
          }
        }

        @media all and (max-width: 1599px) {
          &.no-summary {
            padding-bottom: 5em;
          }
        }

        @media all and (max-width: 898px) {
          &.no-summary {
            padding-bottom: 3em;
          }
        }

        &-text {
          font-family: "Graphik";
          font-size: 0.75em;
          white-space: pre-wrap;

          .LinesEllipsis-ellipsis {
            font-weight: bold;
          }
        }

        &-title {
          font-family: "Graphik-Bold", "Roboto", sans-serif;
          font-size: 0.85em;
          white-space: pre-wrap;

          .LinesEllipsis-ellipsis {
            font-weight: bold;
          }
          a {
            color: #222222;
          }
        }

        @media all and (max-width: 898px) {
          &-text {
            font-size: 0.75em;
          }

          &-title {
            font-size: 0.75em;
          }
        }
      }

      @media all and (max-width: 1599px) {
        &-content {
          padding-bottom: 0em;
        }
      }

      &-footer {
        display: flex;
        align-items: flex-end;

        span {
          font-family: "Graphik";
          font-size: 0.95em;
          color: #cccccc;
        }

        @media all and (max-width: 1279px) {
          span {
            font-size: 0.6em;
            bottom: 2em;
          }
        }

        @media all and (max-width: 898px) {
          span {
            bottom: 3em;
          }
        }
      }
    }
  }

  &-events {
    &-card {
      flex-direction: row;
      padding: 0.8em 0em 0.2em 0em;
    }

    &-image {
      img {
        width: 100%;
        object-fit: contain;
        background: #cccccc;
      }
      &.no-thumbnail {
        display: none;
      }
    }

    @media all and (max-width: 1599px) {
      &-image {
        img {
          background: #cccccc;
        }
      }
      &-header {
        padding-bottom: 0em;
      }
    }

    @media all and (max-width: 1279px) {
      &-image {
        img {
          background: #cccccc;
        }
      }
    }

    @media all and (max-width: 898px) {
      &-image {
        img {
          background: #cccccc;
        }
      }
      &-title {
        a {
          font-size: 0.65em;
        }
      }
    }

    &-info {
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;

      &-date > div {
        display: inline-flex;
        vertical-align: text-top;
      }

      &-locationicon {
        display: inline-flex;
        padding-right: 0.5em;
        vertical-align: text-bottom;

        @media all and (max-width: 767px) {
          vertical-align: bottom;
        }

        svg {
          font-size: 19px !important;
        }
      }
      &-header {
        display: flex;
        flex-direction: row;
        height: fit-content;

        &-icon {
          padding-right: 0.5em;

          svg {
            font-size: 34px !important;
          }
        }

        &-title {
          font-family: "ArcherSSm-Bold", "Roboto", sans-serif;
          font-size: 1.2em;
          padding-top: 0.1em;
          a {
            color: #222222;
            text-decoration: none;
          }
        }

        @media all and (max-width: 898px) {
          &-title {
            font-size: 0.75em;
          }
          &-icon {
            padding-right: 0.3em;
            svg {
              font-size: 24px !important;
            }
          }
        }
      }

      &-content {
        width: 100%;

        &.no-summary {
          padding-bottom: 6em;

          .c-dashboard__tile-stream-events-info-content-text {
            height: 0;
          }
        }

        @media all and (max-width: 1599px) {
          &.no-summary {
            padding-bottom: 5em;
          }
        }

        @media all and (max-width: 898px) {
          &.no-summary {
            padding-bottom: 3em;
          }
        }

        &-text {
          font-family: "Graphik";
          font-size: 0.75em;
          white-space: pre-wrap;

          .LinesEllipsis-ellipsis {
            font-weight: bold;
          }
        }

        &-title {
          font-family: "Graphik-Bold", "Roboto", sans-serif;
          font-size: 0.85em;
          white-space: pre-wrap;

          .LinesEllipsis-ellipsis {
            font-weight: bold;
          }
          a {
            color: #222222;
          }
        }

        @media all and (max-width: 898px) {
          &-text {
            font-size: 0.75em;
          }

          &-title {
            font-size: 0.75em;
          }
        }
      }

      @media all and (max-width: 1599px) {
        &-content {
          padding-bottom: 0em;
        }
      }

      &-footer {
        display: flex;
        justify-content: space-between;
        font-family: "Graphik";
        font-size: 0.8em;
        color: #333333;
        font-weight: 700;
        padding-top: 5px;

        &-right {
          float: right;
        }

        @media all and (max-width: 1279px) {
          font-size: 0.7em;
          bottom: 2em;
        }

        @media all and (max-width: 898px) {
          bottom: 3em;
          font-size: 0.7em;
        }

        @media all and (max-width: 767px) {
          font-size: 0.6em;
        }
      }
    }
  }
}
