.c-health-report__container {
  height: 100%;
  margin: 1em 0;
  display: flex;
  align-items: stretch;
  justify-content: center;

  > * {
    vertical-align: middle;
  }

  .c-health-report__defect-count-container {
    text-align: center;
    display: flex;
    width: 50%;
    border-right: 1px dashed #ccc;
    margin: 0.5em 0 0.5em 2em;
    flex-direction: column;
    align-items: center;
    justify-content: inherit;

    &.c-health-report__full_width {
      border-right: none;
      width: 100%;
    }

    .c-health-report__report-title {
      color: #333333;
    }
    .c-health-report__total-defect-count {
      color: #e0544a;
      font-family: "Graphik", "Roboto", sans-serif;
      font-size: 2em;
      font-weight: 100;
    }
    .c-health-report__report-sub-text {
      color: #bababa;
      font-family: "Graphik", "Roboto", sans-serif;
      font-size: 0.8em;
    }
  }

  .c-health-report__defect-statistics-container {
    display: inline-block;
    width: 50%;
    margin: 0 2em;

    &.c-health-report__hidden {
      display: none;
    }

    .c-health-report__defect {
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      padding-bottom: 0.15em;

      > * {
        display: inline-block;
        font-size: 0.75em;
      }

      .c-health-report__name {
        width: 80%;
        font-family: "Graphik", "Roboto", sans-serif;
        color: #323332;
      }

      .c-health-report__value {
        width: 20%;
        text-align: right;
      }
    }
  }
}
