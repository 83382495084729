.c-species-filter__container {
  display: inline-block;
  width: 56%;
  text-align: right;
  font-family: "Graphik";
  color: #bababa;

  .c-species-filter__label {
    font-size: 0.8em;
    padding-right: 1em;
  }

  .c-species-filter__item {
    > img {
      width: 2.5em;
      margin: 0 0.3em;

      &.show {
        display: inline;
      }

      &.hide {
        display: none;
      }
    }
  }
}

/* Media queries - Responsive timeline on screens between 961px and 1280px wide */
@media all and (min-width: 961px) and (max-width: 1280px) {
  .c-species-filter__container {
    width: 50%;

    .c-species-filter__label {
      display: none;
    }

    .c-species-filter__item {
      > img {
        width: 2.5em;
        margin: 0 0.2em;
      }
    }
  }
}

/* Media queries - Responsive timeline on screens less than 960px wide */
@media all and (max-width: 960px) {
  .c-species-filter__container {
    width: 42%;

    .c-species-filter__label {
      display: none;
    }

    .c-species-filter__item {
      > img {
        width: 2.3em;
        margin: 0 0.2em;
      }
    }
  }
}
