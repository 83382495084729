.c-legal-terms {
  &__container {
    margin-bottom: 2em;
    height: calc(100vh - 10em);
    background: #fff;
    border-radius: 4px;
    width: 100%;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    overflow: hidden;

    &::-webkit-scrollbar {
      width: 0 !important;
    }
  }

  &__header {
    width: 100%;
    border-radius: 4px 4px;
    text-transform: uppercase;
    font-size: 1.2em;
    padding: 2em 2.5em 0.8em 2.5em;
    color: #bababa;
    font-family: "Graphik-Medium", "Roboto", sans-serif;
    letter-spacing: 0.1em;
    position: fixed;
    background-color: white;
  }

  &__textarea {
    padding: 0;
    margin-top: 5em;
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100vh - 10em);
    max-width: 30em;

    canvas {
      margin-left: -7em;
      margin-top: 0em;
    }

    @media all and (max-width: 1024px) {
      canvas {
        margin-left: -5em;
        margin-top: 0em;
      }
    }
  }
}
