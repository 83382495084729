
.c-image-toggler {
    &__container {
      height: 40px;
      width: 100%;
      float: left;
    }
    &__toggler {
      float: right;
      max-width: 150px;
      height: 100%;
      display: flex;
      align-items: center;
  
      img {
        width: 100%;
      }
    }
  }
  