.c-header__app-bar {
  background-color: #fff !important;
  position: absolute;
  -webkit-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0), 0px 4px 5px 0px rgba(0, 0, 0, 0),
    4px 1px 10px 0px rgba(0, 0, 0, 0.22) !important;
  -moz-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0), 0px 4px 5px 0px rgba(0, 0, 0, 0),
    4px 1px 10px 0px rgba(0, 0, 0, 0.22) !important;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0), 0px 4px 5px 0px rgba(0, 0, 0, 0), 4px 1px 10px 0px rgba(0, 0, 0, 0.22) !important;
  height: 3.25em;
  z-index: 1500 !important;

  &.elevated {
    position: fixed !important;
    z-index: 1550 !important;
  }

  .c-header__gutter {
    width: 100%;
    background-color: #fff !important;
    padding-right: 0;
  }

  .c-header__divider {
    flex-grow: 1;
  }

  .c-header__application-title-container {
    min-width: 10em;
    max-width: 10em;
  }

  .c-header__application-title {
    font: normal 1.5em "ArcherSSm-Bold", "Roboto", sans-serif;
    margin-block-start: 1em;
    font-size: 1em;
    text-shadow: 0 0 #000;
    -webkit-font-smoothing: antialiased;
  }

  .c-header__application-subtitle {
    margin-top: -1.7em;
    font-size: 0.7em;
    color: #9b9b9b;
  }

  .notification-badge > span {
    top: 5px;
    right: 5px;
    height: 10px;
    min-width: 10px;
    background-color: #e0544a;
  }

  .c-header__search-input-empty {
    width: 100%;
    padding-left: 1.2em;
    font-family: "Graphik-Light", "Roboto", "sans-serif";
    font-size: 1.1em;
    color: rgb(155, 155, 155);
  }

  .c-header__search-input {
    width: 100%;
    padding-left: 1.2em;
    font-family: "Graphik", "Roboto", "sans-serif";
    font-size: 1.1em;
    color: rgb(51, 51, 51);
  }

  .c-header__search-close-icon {
    padding: 0 0.35em;
    color: rgb(51, 51, 51);
  }
}

#c-header__app-bar-menu {
  position: absolute;
  z-index: 1600;
}

.c-header__icon-button {
  padding: 0.41em 0.35em;
  box-shadow: -1px 0 0 #f4f4f4;

  &.show {
    animation: iconShow 0.25s forwards;
  }

  &.hide {
    animation: iconHide 0.25s forwards 0.25s;
  }
}

@keyframes iconShow {
  from {
    opacity: 0;
    width: 0;
  }

  to {
    opacity: 1;
    width: 3.1em;
  }
}

@keyframes iconHide {
  from {
    opacity: 1;
    width: 3.1em;
  }

  to {
    opacity: 0;
    width: 0;
  }
}
