.c-load-error {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 5em;
  }
  &__message {
    font-family: "Graphik";
    font-weight: 100;
    font-size: 1.1em;
    margin-bottom: 0.5em;
  }
  &__button {
    > button {
      color: #fff;
      background: #427b5d;
      padding-right: 0.8em;
    }
    &-text {
      color: white;
      font-family: "Graphik";
      font-weight: 100;
      font-size: 0.9em;
    }
    &-icon {
      color: white;
      margin-right: 0.5em;
    }
  }
  &-biReport {
      box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.16) !important;
      height: 50vh;
      justify-content: center;
      align-items: center;
      display: inline-flex;
      margin: 1em 2em 1em 5em;
      width: 80%;
  }
  &-biErrorButton {
    background: #fff;
    border: 1px solid #427b5d;
    border-radius: 0;
  }
  &-biErrorText {
    font-family: "Graphik-SemiBold";
    color: #427b5d;
    font-weight: 100;
    font-size: 0.9em;
  }
  &-biErroricon {
    font-family: "Graphik-SemiBold";
    color: #427b5d;
  }
}
