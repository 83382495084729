.c-kill-sheet-hit-rate__eq {
  background: #000;
  color: #fff;
  line-height: 0.5em;

  .c-kill-sheet-hit-rate__eq-image-container {
    width: 75%;
    display: inline-block;
    vertical-align: text-top;

    .c-kill-sheet-hit-rate__eq-image {
      width: auto;
      height: 1.5em;
    }
  }

  .c-kill-sheet-hit-rate__eq-rate {
    width: 25%;
    display: inline-block;
    vertical-align: sub;
    font-size: 0.85em;
    text-align: right;
    padding-right: 0.5em;
  }
}

.c-kill-sheet-hit-rate__grading-text {
  font-family: "Graphik-Medium", "Roboto", sans-serif;
  font-size: 0.75em;
  margin: 1em 0;
}

.c-kill-sheet-hit-rate__ratio-bar {
  width: 100%;
  display: inline-block;
  color: #fff;
  font-size: 0.6em;
  line-height: 1.75em;

  .c-kill-sheet-hit-rate__achieved {
    background: #417a5d;
    width: 50%;
    border-radius: 1em 0 0 1em;
    display: inline-block;
    padding-left: 1em;
  }

  .c-kill-sheet-hit-rate__unachieved {
    background: #e0544a;
    width: 50%;
    border-radius: 0 1em 1em 0;
    display: inline-block;
    padding-right: 1em;
    text-align: right;
  }
}

.c-kill-sheet-hit-rate__error-message {
  text-align: center;
  font-size: 0.75em;
}
