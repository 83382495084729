$smartbanner-close-margin: 5px;
$smartbanner-close-size: 17px;
$smartbanner-icon-margin: 12px;
$smartbanner-icon-size: 57px;
$smartbanner-button-size: 110px;

.smartbanner-show {
  &.smartbanner-margin-top {
    margin-top: 80px;
  }

  &.smartbanner-margin-bottom {
    margin-bottom: 80px;
  }

  .smartbanner {
    display: block;
  }
}

/** Default **/
.smartbanner {
  left: 0;
  display: none;
  width: 100%;
  height: 80px;
  line-height: 80px;
  font-family: "Helvetica Neue", sans-serif;
  background: #f4f4f4;
  z-index: 9998;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  -webkit-text-size-adjust: none;
}

.smartbanner-top {
  position: absolute;
  top: 0;
}

.smartbanner-bottom {
  position: fixed;
  bottom: 0;
}

.smartbanner-container {
  margin: 0 auto;
  padding: 0 5px;
}

.smartbanner-close {
  display: inline-block;
  vertical-align: middle;
  margin: 0 $smartbanner-close-margin 0 0;
  font-family: "ArialRoundedMTBold", Arial;
  font-size: 20px;
  text-align: center;
  color: #888;
  text-decoration: none;
  border: 0;
  border-radius: 14px;
  padding: 0 0 1px;
  background-color: transparent;
  -webkit-font-smoothing: subpixel-antialiased;

  &:active,
  &:hover {
    color: #aaa;
  }
}

.smartbanner-icon {
  display: inline-block;
  vertical-align: middle;
  width: $smartbanner-icon-size;
  height: $smartbanner-icon-size;
  margin-right: $smartbanner-icon-margin;
  background-size: cover;
  border-radius: 10px;
}

.smartbanner-info {
  white-space: normal;
  display: inline-block;
  vertical-align: middle;
  width: calc(
    99% - #{$smartbanner-close-margin} - #{$smartbanner-close-size} - #{$smartbanner-icon-margin} - #{$smartbanner-icon-size} -
      #{$smartbanner-button-size}
  );
  font-size: 11px;
  line-height: 1.2em;
  font-weight: bold;
}

.smartbanner-wrapper {
  max-width: $smartbanner-button-size;
  display: inline-block;
  text-align: right;
  width: 100%;
}

.smartbanner-title {
  font-size: 13px;
  line-height: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.smartbanner-description {
  max-height: 40px;
  overflow: hidden;
}

.smartbanner-author {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:empty {
    + .smartbanner-description {
      max-height: 50px;
    }
  }
}

.smartbanner-button {
  margin: auto 0;
  height: 24px;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  font-weight: bold;
  color: #6a6a6a;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);

  &:active,
  &:hover {
    color: #aaa;
  }
}

/** iOS **/
.smartbanner-ios {
  background: #f2f2f2;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  line-height: 80px;

  .smartbanner-close {
    border: 0;
    width: 18px;
    height: 18px;
    line-height: 18px;
    font-family: Arial;
    color: #888;
    text-shadow: 0 1px 0 #fff;
    -webkit-font-smoothing: none;

    &:active,
    &:hover {
      color: #888;
    }
  }

  .smartbanner-icon {
    background-size: cover;
  }

  .smartbanner-info {
    color: #6a6a6a;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
    font-weight: 300;
  }

  .smartbanner-title {
    color: #4d4d4d;
    font-weight: 500;
  }

  .smartbanner-button {
    border: 1px #0c71fd solid;
    border-radius: 8px;
    height: 2.2em;
    padding-top: 0.3em;
    font-weight: bold;
    padding: 0.3em 0.5em 0em 0.5em;
    font-size: 15px;
    min-width: 10%;
    color: #0c71fd;

    &:active,
    &:hover {
      background: #f2f2f2;
    }
  }
}

/** Android **/
.smartbanner-android {
  background: #f2f2f2;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  line-height: 80px;

  .smartbanner-close {
    border: 0;
    width: 18px;
    height: 18px;
    line-height: 18px;
    margin: 0 5px 0 0;
    font-family: Arial;
    color: #888;
    text-shadow: 0 1px 0 #fff;
    -webkit-font-smoothing: none;
    max-width: none;
    background: none;
    box-shadow: none;
    text-decoration: none;
    cursor: pointer;

    &:active,
    &:hover {
      color: #eee;
    }
  }

  .smartbanner-icon {
    background-color: transparent;
    box-shadow: none;
  }

  .smartbanner-info {
    color: #6a6a6a;
    text-shadow: 0 1px 0 hsla(0, 0%, 100%, 0.8);
    font-weight: 300;
  }

  .smartbanner-title {
    color: #4d4d4d;
    font-weight: 500;
  }

  .smartbanner-button {
    border: 1px #0c71fd solid;
    border-radius: 8px;
    height: 2.2em;
    padding: 0em 0.5em 0em 0.5em;
    font-weight: bold;
    font-size: 15px;
    min-width: 10%;
    color: #0c71fd;
    box-shadow: none;
    background: none;

    &:active,
    &:hover {
      background: none;
    }
  }

  .smartbanner-button-text {
    background: none;
    text-transform: uppercase;
    padding: 0.3em 0em 0em 0em;
    text-align: center;
    display: block;
    text-shadow: none;
    box-shadow: none;

    &:active,
    &:hover {
      background: #2ac7e1;
    }
  }
}
