.p-dashboard {
  &__container {
    background: rgba(0, 0, 0, 0.02);
    overflow-y: hidden;
    height: calc(100vh - 3.25em);
    min-width: 640px;
  }

  &__tiles {
    margin: 1.2em 1em 1em 1.5em;
  }

  &__header {
    font-size: 1.3em;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  &__sub-header {
    margin-top: 0;
    font-size: 0.85em;
    font-family: "Graphik-Medium";
    padding-bottom: 0.5em;
    > span {
      font-family: "Graphik", "Roboto", "sans-serif";
      font-size: 0.9em;
      margin-left: 1em;
      color: #9b9b9b;
    }
  }

  &__grid {
    height: calc(105vh - 8.69em);

    @media all and (min-width: 961px) and (max-width: 1024px) {
      &-mdlg-tiles {
        @include grid5;
      }

      &-mdlg-streams {
        @include grid7;
      }
    }
  }

  &__scroll-container {
    overflow: hidden;
    overflow-y: auto;
    height: 100%;
    padding-bottom: 3em!important;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    > div {
      padding-bottom: 12px;
    }

    > div > div {
      width: 100%;
    }
  }

  /* Chrome */
  &__scroll-container::-webkit-scrollbar {
    display: none;
  }

  &__scroll-container {
    > div > div {
      width: 100%;
    }
  }
}
