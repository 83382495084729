.c-kill-sheet-container {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  height: calc(100vh - 3.25em);
}

.c-timeline__container-extended {
  @extend .c-timeline__container;
  width: 100%;
}

.c-timeline__container {
  display: inline-block;
  min-width: 39em;
  overflow: auto;
  -ms-overflow-style: none;
  overflow: "-moz-scrollbars-none";
  scrollbar-width: none;

  > div {
    max-width: 34em;
  }

  &.farm-details {
    min-width: 41em;
  }

  &::-webkit-scrollbar {
    width: 0 !important;
  }

  .c-timeline__load-error-container {
    margin: 2em 2.5em;
    text-align: center;
  }

  .c-timeline__empty-kill-sheets-container {
    margin: 2em 2.5em;
    text-align: center;

    .c-timeline__empty-kill-sheets-text {
      font-family: "Graphik", "Roboto", sans-serif;
      font-weight: 100;
      font-size: 1.2em;
      margin-bottom: 1em;
    }

    .c-timeline__refresh-button-container {
      > button {
        color: #fff;
        background: #427b5d;
      }

      .c-timeline__refresh-icon {
        margin-right: 0.5em;
      }
    }
  }
}

.c-timeline__header {
  font-size: 1.2em;
  margin-bottom: -0.5em;
  margin-top: 1em;
}

.c-timeline__header-container {
  margin-bottom: 1em;
  margin-left: 5em;
  max-width: 37.5em;
}

.c-timeline__header-search-result {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  max-width: 39em !important;
  margin-left: 5.5em;
  margin-bottom: 1.1em;
  font-family: "Graphik", "Roboto", "sans-serif";
  font-size: 0.9em;
  color: rgb(51, 51, 51);
}

.c-timeline__hidden {
  visibility: hidden;
}

.c-timeline__legend {
  width: fit-content;
  display: inline-block;
  white-space: nowrap;

  .c-timeline__legend-icon {
    display: inline-block;
    border-radius: 50%;
    border-width: 0;
    height: 0.8em;
    width: 0.8em;
    vertical-align: middle;
    margin-right: 10px;
  }

  .c-timeline__legend-text {
    margin-right: 1em;
    font-size: 0.8em;
    color: #9b9b9b;
    font-family: "Graphik-Medium";
  }
}

.c-timeline__legend-icon-killsheet {
  background-color: #427b5d;
}

.c-timeline__legend-icon-interim {
  background-color: #b0b569;
}

/* The actual timeline (the vertical ruler) */
.c-timeline__timeline {
  position: relative;
  top: 1em;
  margin-bottom: 5em;

  &::after {
    content: "";
    position: absolute;
    width: 1px;
    background-color: #ccc;
    top: 1em;
    bottom: 0;
    left: 2.6em;
    height: calc(
      100% + 8em
    ); /* The height needs to be set a little higher to accommodate the progress component on Safari browsers and the sticky FABs too */
    min-height: 100vh;
  }
}

.c-timeline-fab__container {
  bottom: 2em;
  width: 10em;
  z-index: 100;
  position: -webkit-sticky !important;
  position: sticky !important;

  @media all and (max-width: 1024px) {
    bottom: 4em;
  }

  .c-timeline-fab {
    top: 0;
    padding: 5px;
    width: 2.5em;
    height: 1em;
    background: #fff;
    color: rgba(0, 0, 0, 0.16);
    margin: 0.5em 2.5em;
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .c-timeline-fab.up span {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

/* Container around content */
.c-timeline__content-container-final {
  width: 35em;
  height: 14em;
  padding: 0.5em 1em;
  position: relative;
  background-color: inherit;

  /* The circles on the timeline */
  &::after {
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    background-color: #427b5d;
    border: 4px solid #427b5d;
    top: 5.9em;
    border-radius: 50%;
    z-index: 1;
  }
}

/* Container around content */
.c-timeline__content-container-interim {
  width: 35em;
  height: 14em;
  padding: 0.5em 1em;
  position: relative;
  background-color: inherit;

  /* The circles on the timeline */
  &::after {
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    background-color: #b0b569;
    border: 4px solid #b0b569;
    top: 5.9em;
    border-radius: 50%;
    z-index: 1;
  }
}

.c-timeline__initial-year-container {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    width: 0.65em;
    height: 0.65em;
    background-color: #ccc;
    top: 0.35em;
    border-radius: 50%;
    z-index: 1;
    left: 2.3em;
  }

  .c-timeline__initial-year {
    transform: translateX(-50%) translateY(-50%) rotate(90deg);
    width: 1em;
    font-size: 1em;
    color: #ccc;
    margin-left: 1em;
  }
}

/* Place the container to the left */
.c-timeline__left {
  left: 0;
}

/* Place the container to the right */
.c-timeline__right {
  left: 0;
  top: -1.5em;
  margin-bottom: 0em;

  &::before {
    content: " ";
    height: 0;
    position: absolute;
    left: 2.6em;
    top: 7.5em;
    width: 3.6em;
    z-index: 0;
    border-top: 1px solid #ccc;
  }

  &.farm-details {
    &::before {
      width: 4.5em;
    }
  }

  &::after {
    left: 2em;
    top: 6.9em;
  }
}

/* Add arrows to the left container (pointing right) */
.c-timeline__left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 6.5em;
  width: 4.6em;
  z-index: 0;
  left: 4.6em;
  border-top: 1px solid #ccc;
}

/* The actual content */
.c-timeline__content {
  background-color: white;
  position: relative;
  margin-left: 4em;
  width: 100%;
  height: 100%;
  border-radius: 0.3em;
  box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  cursor: pointer;

  &.farm-details {
    margin-left: 6em;
  }

  &.interim-shadow {
    box-shadow: 0.5em 0.83em 1.67em rgba(176, 181, 105, 0.58);
  }

  &.final-shadow {
    box-shadow: 0.5em 0.83em 1.67em rgba(66, 123, 93, 0.58);
  }

  > div {
    height: inherit;
  }

  .c-timeline__selected-chevron {
    border-bottom-left-radius: 2em;
    border-top-left-radius: 2em;
    height: 3em;
    width: 1.5em;
    position: absolute;
    right: 0;
    top: 5em;
    box-shadow: -0.17em 0 0.25em rgba(0, 0, 0, 0.16);

    &.show {
      -webkit-animation: slideIn 0.25s forwards;
      animation: slideIn 0.25s forwards;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;

      > svg {
        -webkit-animation: rotateClockWise 0.25s forwards;
        animation: rotateClockWise 0.25s forwards;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
      }
    }

    &.hide {
      -webkit-animation: slideOut 0.25s forwards;
      animation: slideOut 0.25s forwards;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;

      > svg {
        -webkit-animation: rotateCounterClockWise 0.25s forwards;
        animation: rotateCounterClockWise 0.25s forwards;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
      }
    }

    &.interim {
      background: #b0b569;
    }

    &.final {
      background: #427b5d;
    }

    > svg {
      margin: 0.75em 0.25em;
      color: #fff;
    }
  }
}

.c-timeline__type-detail {
    width: 100%;
  height: 100%;
  padding: 0 2em 0 5.5em;
}

.c-timeline__type {
  font-family: "Graphik-SemiBold", "Roboto", "sans-serif";
  text-transform: capitalize;
  width: 4em;
  height: 100%;
  float: left;
  border-radius: 0.3em 0 0 0.3em;

  &.final {
    background: #427b5d;
  }

  &.interim {
    background: #b0b569;
  }

  .c-timeline__type-content {
    position: absolute;
    top: 50%;
    left: 5%;
    font-size: 1.5em;

    .c-timeline__type-content-text {
      -webkit-transform: translateX(-50%) translateY(-50%) rotate(90deg);
      transform: translateX(-50%) translateY(-50%) rotate(90deg);
      color: #fff;
      margin-left: 1.05em;
      text-align: center;

      .c-timeline__type-content-species {
        letter-spacing: 0.1em;
      }
    }

    .c-timeline__type-content-categories {
      font-family: "ArcherSSm-Bold", "Roboto", "sans-serif";

      .c-timeline__type-content-category {
        background: #fff;
        font-size: 0.75rem;
        border-radius: 1em;
        padding: 0 0.55em;
        margin-right: 0.5em;

        &.c-timeline__category-final {
          color: #427b5d;
          border: 1px solid #427b5d;
        }

        &.c-timeline__category-interim {
          color: #b0b569;
          border: 1px solid #b0b569;
        }
      }
    }
  }
}

.c-timeline__final-dashed-bottom-border {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.16);
  margin: 1em 0 0.5em 0;
}

.c-timeline__interim-dashed-bottom-border {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.16);
  margin: 0.5em 0 0.5em 0;
}

.c-timeline__message-container {
  padding: 1em 0 0.5em 0;
  font-family: "Graphik", "Roboto", "sans-serif";
  font-size: 0.9em;
}

.c-timeline__attribute-container {
  font-family: "Graphik-Medium", "Roboto", "sans-serif";
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .c-timeline__attribute {
    padding: 0 0.5em 0.75em 0;

    &.primary {
      font-size: 1.3em;

      &.interim {
        padding: 0.25em 0.5em 0.75em 0;

        &.primary-attribute-column-1 {
          min-width: 12.5%;
          max-width: 50%;
        }

        &.primary-attribute-column-2 {
          min-width: 27.5%;
          max-width: 50%;
        }
      }

      &.final {
        padding: 0.25em 0.5em 0.75em 0;
      }

      .c-timeline__attribute-subheading {
        font-family: "Graphik", "Roboto", "sans-serif";
        font-size: 0.45em;
        color: #bababa;
      }
    }

    &.primary-attribute-column-1 {
      width: 12.5%;
    }

    &.primary-attribute-column-2 {
      width: 27.5%;
    }

    &.primary-attribute-column-3 {
      width: 32.5%;
    }

    &.primary-attribute-column-4 {
      width: 27.5%;
    }

    &.secondary-attribute-column {
      width: 33.3333%;
      padding: 0.4em 0.5em 0.4em 0;
    }

    &.secondary {
      font-size: 0.9em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .c-timeline__attribute-subheading {
        font-family: "Graphik", "Roboto", "sans-serif";
        font-size: 0.75em;
        color: #bababa;
      }
    }
  }
}

.c-timeline__period {
  position: absolute;
  top: 55%;
  margin: auto;
  font-size: 0.8em;
  transform: translateX(-50%) translateY(-50%) rotate(90deg);
  margin-left: 0.25em;
}

/* Media queries - Responsive timeline on screens between 961px and 1280px wide */
@media all and (min-width: 961px) and (max-width: 1280px) {
  .timeline__container-extended {
    max-width: 31em;

    > div {
      max-width: 31em;
    }
  }
  .c-timeline__container {
    display: inline-block;
    min-width: 36em;
    overflow: auto;
    -ms-overflow-style: none;
    overflow: "-moz-scrollbars-none";
    scrollbar-width: none;

    > div {
      max-width: 31em;
    }

    &.farm-details {
      min-width: 38.5em;
    }
  }

  .c-timeline__header-search-result {
    max-width: 35.7em !important;
  }

  .c-timeline__header {
    margin-bottom: -0.25em;
  }

  .c-timeline__period {
    top: 53%;
    margin-left: 0.5em;
  }

  .c-timeline__message-container {
    font-size: 0.8em;
  }

  .c-timeline__right {
    &::before {
      left: 2.6em;
      top: 7em;
    }

    &::after {
      top: 6.4em;
    }
  }

  .c-timeline__type {
    width: 3.25em;

    .c-timeline__type-content {
      .c-timeline__type-content-text {
        margin-left: 0.55em;
      }
    }
  }

  .c-timeline__type-detail {
    width: 100%;
    height: 100%;
    padding: 1em 1.75em 0 4.5em;
  }

  .c-timeline__content-container-final {
    width: 32em;
    height: 13em;

    &::after {
      top: 6.4em;
    }
  }

  .c-timeline__content-container-interim {
    width: 32.5em;
    height: 13em;

    &::after {
      top: 6.4em;
    }
  }

  .c-timeline__content {
    .c-timeline__selected-chevron {
      &.show {
        top: 5em;
      }

      &.hide * {
        display: none;
      }
    }
  }

  .c-timeline__attribute-container {
    padding-top: 0.5em;

    .c-timeline__attribute {
      padding: 0 0.5em 0.5em 0;

      &.primary {
        font-size: 1.2em;

        &.interim {
          padding: 0 0.5em 0.5em 0;
        }

        &.final {
          padding: 0 0.5em 0.5em 0;
        }

        .c-timeline__attribute-subheading {
          font-size: 0.5em;
        }
      }

      &.secondary {
        font-size: 0.85em;
      }
    }
  }

  .c-timeline__final-dashed-bottom-border {
    margin: 0;
  }

  .c-timeline__interim-dashed-bottom-border {
    margin: 0;
  }
}

/* Media queries - Responsive timeline on screens less than 960px wide */
@media all and (max-width: 960px) {
  .c-timeline__container {
    max-width: 31.5em;
    min-width: 31.5em;

    > div {
      max-width: 31.5em;
    }

    &.farm-details {
      min-width: 33.5em;
    }

    .c-timeline__empty-kill-sheets-container {
      margin: 2em 5em;

      .c-timeline__empty-kill-sheets-text {
        font-size: 1em;
      }
    }
  }

  .c-timeline__header-search-result {
    max-width: 30.7em !important;
  }

  .c-timeline__header {
    margin-bottom: -0.25em;
  }

  .c-timeline__period {
    top: 50%;
    margin-left: 0.25em;
  }

  .c-timeline__message-container {
    padding: 1em 0 0.5em 0;
  }

  .c-timeline__type {
    width: 3.25em;

    .c-timeline__type-content {
      .c-timeline__type-content-text {
        margin-left: 0.85em;
      }
    }
  }

  .c-timeline__type-detail {
    width: 100%;
    height: 100%;
    padding: 0.5em 2em 0 5.5em;
  }

  .c-timeline__content-container-final {
    width: 27.5em;
    height: 22em;

    &::after {
      top: 11.9em;
    }

    &.c-timeline__right {
      &::before {
        left: 2.6em;
        top: 11em;
      }

      &::after {
        top: 10.4em;
      }
    }
  }

  .c-timeline__content-container-interim {
    width: 27.5em;
    height: 21em;

    &::after {
      top: 10.4em;
    }

    &.c-timeline__right {
      &::before {
        left: 2.6em;
        top: 10.5em;
      }

      &::after {
        top: 9.9em;
      }
    }
  }

  .c-timeline__content {
    .c-timeline__selected-chevron {
      &.show {
        top: 8em;
      }

      &.hide * {
        display: none;
      }
    }
  }

  .c-timeline__attribute-container {
    padding-top: 0.5em;

    .c-timeline__attribute {
      padding: 0 0.5em 0.75em 0;

      &.primary {
        font-size: 1.2em;

        &.interim {
          padding: 0 0.5em 0.75em 0;
        }

        &.final {
          padding: 0 0.5em 0.75em 0;
        }

        .c-timeline__attribute-subheading {
          font-size: 0.5em;
        }
      }

      &.secondary {
        font-size: 0.8em;
      }

      &.primary-attribute-column-1 {
        width: 50%;
      }

      &.primary-attribute-column-2 {
        width: 50%;
      }

      &.primary-attribute-column-3 {
        width: 50%;
      }

      &.primary-attribute-column-4 {
        width: 50%;
      }

      &.secondary-attribute-column {
        width: 50%;
        padding: 1em 0.5em 0.4em 0;
      }
    }
  }
}

/* IE10+ will use `position: fixed` as `position: sticky` is not supported on IE browsers. */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .c-timeline-fab__container {
    position: fixed !important;

    .c-timeline-fab {
      position: relative !important;
    }
  }
}

@-webkit-keyframes slideIn {
  0% {
    right: -1.5em;
    opacity: 0;
    z-index: -100;
  }
  50% {
    right: -0.75em;
    opacity: 0.5;
    z-index: -1;
  }
  100% {
    right: 0em;
    opacity: 1;
    z-index: 1;
  }
}

@keyframes slideIn {
  0% {
    right: -1.5em;
    opacity: 0;
    z-index: -100;
  }
  50% {
    right: -0.75em;
    opacity: 0.5;
    z-index: -1;
  }
  100% {
    right: 0em;
    opacity: 1;
    z-index: 1;
  }
}

@-webkit-keyframes slideOut {
  0% {
    right: 0em;
    opacity: 1;
    z-index: 1;
  }
  50% {
    right: -0.75em;
    opacity: 0.5;
    z-index: -1;
  }
  100% {
    right: -1.5em;
    opacity: 0;
    z-index: -100;
  }
}

@keyframes slideOut {
  0% {
    right: 0em;
    opacity: 1;
    z-index: 1;
  }
  50% {
    right: -0.75em;
    opacity: 0.5;
    z-index: -1;
  }
  100% {
    right: -1.5em;
    opacity: 0;
    z-index: -100;
  }
}

@-webkit-keyframes rotateClockWise {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

@keyframes rotateClockWise {
  0% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@-webkit-keyframes rotateCounterClockWise {
  0% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes rotateCounterClockWise {
  0% {
    transform: rotate(20deg);
  }
  50% {
    transform: rotate(20deg);
  }
  100% {
    transform: rotate(20deg);
  }
}

