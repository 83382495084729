.c-feedback {
  &__content-container {
    width: 85%;
    max-width: 40em;
  }

  &__container {
    background: #fff;
    border-radius: 4px 0 0 4px;
    width: 100%;
    overflow: auto;
    overflow: "-moz-scrollbars-none";
    -ms-overflow-style: none;
    scrollbar-width: none;
    min-height: 30em;
    height: 100%;

    &::-webkit-scrollbar {
      width: 0 !important;
    }
  }

  &__header {
    width: 100%;
    text-transform: uppercase;
    font-size: 1.2em;
    padding: 2em 2.5em 1em 2.5em;
    color: #bababa;
    font-family: "Graphik-Medium", "Roboto", sans-serif;
    letter-spacing: 0.1em;
  }

  &__input {
    * {
      font-family: "Graphik", "Roboto", sans-serif !important;
      font-size: 1em !important;
      line-height: 1.5em !important;
    }

    > div {
      &::before {
        border-bottom: none !important;
      }

      &::after {
        border-bottom: none !important;
      }
    }
  }

  &__textarea {
    border: 1px solid #bababa;
    border-radius: 4px;
    margin: 0 3em;
    padding: 0 1em 1em 1em;

    &.focused {
      border: 1px solid #427b5d;
    }
  }

  &__counter {
    font-family: "Graphik", "Roboto", sans-serif !important;
    font-size: 0.9em;
    color: #bababa;
    text-align: right;
    letter-spacing: 0.1em;
  }

  &__action-container {
    margin: 1em 3em;
  }

  &__submit-button {
    color: #427b5d !important;
    border: 1px solid #427b5d !important;
    font-family: "Graphik-Semibold", "Roboto", sans-serif !important;
    font-size: 0.9em !important;
    letter-spacing: 0.05em !important;
    border-radius: 0 !important;
    height: 3em;

    &:disabled {
      color: #bababa !important;
      border: 1px solid #bababa !important;
    }

    &:hover {
      background-color: rgba(66, 123, 93, 0.07) !important;
    }
  }

  &__feedback-submission-progress {
    color: #427b5d !important;
    width: 1.75em !important;
    height: 1.75em !important;
  }
}

@media all and (max-width: 960px) {
  .c-feedback {
    &__container {
      width: 75%;
      min-width: 30em;
    }
  }
}
