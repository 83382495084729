.c-shares-list {
  &__empty-container {
    width: 100%;
    max-width: 50em;
    min-width: 40em;
    height: 72vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__empty-text {
    font-family: "Graphik";
    font-weight: 100;
    font-size: 1.1em;
    text-align: center;
    margin-top: 2em;
  }
}

.c-share {
  &__container {
    background-color: white;
    width: 31em;
    border-radius: 0.25em;
    margin: 1.5em 5em 0 5em;
    padding: 2em;
    box-shadow: 0 0.15em 0.8em 0 rgba(0, 0, 0, 0.16);
  }

  &__crn {
    border-bottom: 0.05em dashed black;
    padding-bottom: 1.2em;
  }

  &__crn-value {
    font-family: "Graphik-Medium", "Roboto", "sans-serif";
    font-size: 1.6em;
    letter-spacing: 0.03em;
  }

  &__crn-label {
    font-family: "Graphik", "Roboto", "sans-serif";
    font-size: 0.8em;
    letter-spacing: 0.015em;
  }

  &__type-quantity {
    display: flex;
    justify-content: space-between;
    padding-top: 1.2em;
  }

  &__type-value {
    font-family: "Graphik-Medium", "Roboto", "sans-serif";
    font-size: 1.1em;
    letter-spacing: 0.021em;
  }

  &__quantity-value {
    font-family: "Graphik-Medium", "Roboto", "sans-serif";
    font-size: 1.1em;
    letter-spacing: 0.021em;
    text-align: right;
  }

  &__type-label {
    font-family: "Graphik", "Roboto", "sans-serif";
    font-size: 0.8em;
    letter-spacing: 0.015em;
  }

  &__quantity-label {
    font-family: "Graphik", "Roboto", "sans-serif";
    font-size: 0.8em;
    letter-spacing: 0.015em;
    text-align: right;
  }
}

@media all and (max-width: 1024px) {
  .c-share {
    &__container {
      margin-left: 2em;
      width: 27em;
    }
  }
}
