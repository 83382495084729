.c-plus-minus-button {
  &__container {
    display: flex;
    box-shadow: 0.05em 0.15em 0.15em 0 rgba(0, 0, 0, 0.08);
    border-radius: 0.25em;
  }

  &__text {
    line-height: 2.5em;
    width: 8em;
    font-size: large;
    background-color: white;
    letter-spacing: 0.03em;
    border-left: 0.05em solid rgba(112, 112, 112, 0.13);
    border-right: 0.05em solid rgba(112, 112, 112, 0.13);
  }

  &__error {
    box-shadow: 0 0 0.5em 0 rgb(224, 87, 74);
  }
}
