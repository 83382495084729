.p-login__login-page {
  background: url(/public/images/sff_bg.png) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100vh;
  background-position: bottom;
}

.p-login__login-container {
  text-align: center;
  width: 50vw;
  margin: 0 auto;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);

  @media all and (min-width: 601px) and (max-width: 725px) {
    width: 75%;
  }

  @media all and (max-width: 600px) {
    width: 100%;
  }
}

.p-login__login-header {
  font-family: "Graphik-Semibold", "Roboto", sans-serif;
  margin: 0 auto 15vh auto;

  .app-logo {
    width: 25%;
    margin: 3em auto 1em auto;

    @media all and (min-width: 1100px) and (max-width: 1600px) {
      width: 40%;
    }

    @media all and (min-width: 900px) and (max-width: 1099px) {
      width: 40%;
    }

    @media all and (min-width: 799px) and (max-width: 899px) {
      width: 50%;
    }

    @media all and (min-width: 601px) and (max-width: 800px) {
      width: 50%;
    }

    @media all and (max-width: 600px) {
      width: 50%;
    }
  }
}

.p-login__b2c-button-container {
  width: 30%;
  margin: 0 auto 1em auto;

  @media all and (min-width: 1100px) and (max-width: 1600px) {
    width: 45%;
    margin: 3em auto;
  }

  @media all and (min-width: 900px) and (max-width: 1099px) {
    width: 55%;
    margin: 3em auto;
  }

  @media all and (min-width: 799px) and (max-width: 899px) {
    width: 60%;
    margin: 3em auto;
  }

  @media all and (min-width: 601px) and (max-width: 800px) {
    width: 70%;
    margin: 3em auto;
  }

  @media all and (min-width: 401px) and (max-width: 600px) {
    width: 60%;
    margin: 3em auto;
  }

  @media all and (max-width: 400px) {
    width: 75%;
    margin: 3em auto;
  }
}

.p-login__b2c-button-container button {
  font-family: "Graphik-Semibold", "Roboto", sans-serif;
  border: none;
  padding: 12px 18px;
  text-transform: uppercase;
  cursor: pointer;
  color: #4a8063;
  background-color: #fff;
  outline: none;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  background-position: center;
  transition: background 0.8s;
  font-size: 0.75em;
  margin-bottom: 2em;
  font-weight: 600;
  letter-spacing: 0.1em;
}

.p-login__links-container {
  width: 30%;
  margin: 0 auto;

  @media all and (min-width: 1100px) and (max-width: 1600px) {
    width: 45%;
    margin: 3em auto;
  }

  @media all and (min-width: 900px) and (max-width: 1099px) {
    width: 55%;
    margin: 3em auto;
  }

  @media all and (min-width: 799px) and (max-width: 899px) {
    width: 60%;
    margin: 3em auto;
  }

  @media all and (min-width: 601px) and (max-width: 800px) {
    width: 70%;
    margin: 3em auto;
  }

  @media all and (min-width: 401px) and (max-width: 600px) {
    width: 60%;
    margin: 3em auto;
  }

  @media all and (max-width: 400px) {
    width: 75%;
    margin: 3em auto;
  }
}

.p-login__links-container .p-login__link {
  color: #fff;
  font-weight: 600;
  font-size: 0.9rem;
  text-decoration: underline;
  font-family: "Graphik-Medium";
  font-weight: 100;
}

.p-login__links-container .p-login__about-sff-link {
  float: left;
}

.p-login__links-container .p-login__contact-us-link {
  float: right;
}

.dialogTitle{
  font-family: 'Graphik-Medium';
  font-size: 1em;
}

.dialogContent{
  box-shadow: -6px 0 white, 6px 0 white, 0 5px 4px -2px #C0C0C0;
}


.dialogButton {
  color: white!important;
  border: 1px solid #427b5d !important;
  font-family: "Graphik-Semibold", "Roboto", sans-serif !important;
  font-size: 0.9em !important;
  letter-spacing: 0.05em !important;
  border-radius: 0 !important;
  height: 3em;
  float: right;
  min-width: 6em!important;
  background-color: #427b5d !important;

  &:hover {
    background-color: rgba(66, 123, 93, 0.07) !important;
  }
}