@keyframes fadeOutIn {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 0;
    width: 0;
    height: 0;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

#c-menu__drawer {
  position: relative !important;
  z-index: 1550 !important;

  #c-menu__item-container {
    overflow: hidden;
    overflow-y: auto;
    height: 100%;
  }

  > div {
    box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.16);
  }
}

.c-menu__open-drawer-logo-container {
  max-width: "auto";
  overflow: "unset";
}

.c-menu__closed-drawer-logo-container {
  max-width: 58;
  overflow: "hidden";
}

.c-menu__logo {
  &.open-drawer {
    width: 7.5em;
    height: 6em;
    margin: 2em 1.5em 1em 1.5em;
  }

  &.closed-drawer {
    width: 1.5em;
    height: 1.5em;
    margin: 1.5em 0 1.8em 1em;
  }
}

.c-menu__show-logo {
  animation: fadeIn 1s ease-out;
  -webkit-animation: fadeIn 1s ease-out;
  -moz-animation: fadeIn 1s ease-out;
  -o-animation: fadeIn 1s ease-out;
  opacity: 0;
}
