.c-shares-graph {
  &__container {
    display: inline-block;
  }
  &__graph-container {
    position: relative;
    padding: 1em;
    padding-bottom: 4em;
    padding-top: 0.75em;
    margin: 1.5em 2em 1em 5em;
    background-color: white;
    border-radius: 0.3em;
    box-shadow: 0 0.2em 0.5em 0.05em rgba(0, 0, 0, 0.16);
    width: 31em;
    height: 17.25em;
    z-index: 1;
  }
  &__header {
    font-size: 1.5em;
    margin-left: 3.5em;
    margin-bottom: 0.5em;
    text-transform: uppercase;
  }
  &__info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(65, 122, 93, 0.1);
    border-radius: 0.3em;
    padding: 1.2em 1.7em 1.2em 1.7em;
    margin-bottom: 1.5em;
  }

  &__info {
    display: flex;
    flex-direction: column;
    width: 14.5em;
  }

  &__info-header {
    font-family: "Graphik-Medium", "Roboto", "sans-serif";
    font-weight: normal;
  }

  &__info-text {
    font-weight: normal;
    font-size: small;
    font-family: "Graphik", "Roboto", "sans-serif";
  }

  &__shares-owned {
    margin-top: 0.5em;
    float: left;
    font-family: "Graphik", "Roboto", "sans-serif";
    font-size: medium;
    letter-spacing: 0.02em;
  }

  &__stock-supplied {
    margin-top: 0.5em;
    float: right;
    font-family: "Graphik", "Roboto", "sans-serif";
    font-size: medium;
    letter-spacing: 0.02em;
  }
  &__num-shares {
    font-size: xx-large;
    font-family: "Graphik-Medium", "Roboto", "sans-serif";
    letter-spacing: 0.05em;
  }
  &__num-stock {
    font-size: xx-large;
    text-align: right;
    font-family: "Graphik-Medium", "Roboto", "sans-serif";
    letter-spacing: 0.05em;
  }
  &__button-container {
    width: 31em;
    margin: -2em 2em 2em 5em;
    padding-top: 1em;
    background-color: rgb(65, 122, 93);
    border-radius: 0.3em;
    box-shadow: 0 0.2em 0.5em 0.05em rgba(0, 0, 0, 0.16);
    z-index: 0;
  }
  &__tooltip-text {
    border-bottom: 0.05em dotted black;
    margin: 0.5em;
    padding: 1em;
    text-align: center;
  }
  &__tooltip {
    vertical-align: middle;
  }
  &__tooltip-animal {
    border-bottom: 0.05em dotted black;
    text-align: right;
    margin: 0.5em;
    margin-top: -3em;
    padding: 1em;
  }
  &__img {
    width: 3em;
    height: 3em;
    margin-right: -5em;
    margin-left: 0.5em;
    margin-bottom: -0.75em;
  }
}

@media all and (max-width: 1024px) {
  .c-shares-graph__graph-container {
    margin-left: 2em;
  }
  .c-shares-graph__button-container {
    margin-left: 2em;
  }
}
