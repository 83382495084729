.c-interim-kill-sheet__header {
  font-size: 1.2em;
  margin-bottom: -0.5em;
  margin-top: 1em;
}

.c-interim-kill-sheet-details__content-container {
  height: 100vh;
  overflow: hidden;
  width: 100%;
  min-width: 40em;

  .c-interim-kill-sheet-details__header {
    background: #b0b569;
    margin-top: 1.5em;
    padding: 0.6em;
    border-radius: 0.3em 0 0 0;

    * {
      color: #fff;
    }

    .c-interim-kill-sheet-details__header-content {
      max-width: 44em;

      .c-interim-kill-sheet-details__subject-container {
        width: 50%;
        display: inline-block;
        line-height: 2em;

        .c-interim-kill-sheet-details__species-icon {
          > img {
            width: 1.25em;
            height: 1.25em;
            margin: 0 0.5em 0 0.75em;
            vertical-align: middle;
          }
        }

        .c-interim-kill-sheet-details__species-text {
          letter-spacing: 1.5px;
          padding-right: 0.55em;
          vertical-align: middle;
          font-size: 1.2em;
        }

        .c-interim-kill-sheet-details__categories {
          font-family: "ArcherSSm-Bold", "Roboto", "sans-serif";

          .c-interim-kill-sheet-details__category {
            border: 1px solid #fff;
            font-size: 0.75rem;
            border-radius: 1em;
            padding: 0 0.55em;
            margin-right: 0.5em;
            background: #b0b569;
          }
        }
      }

      .c-interim-kill-sheet-details__action-container {
        width: 50%;
        display: inline-block;
        text-align: right;

        .c-interim-kill-sheet-details__date {
          margin: 0 0.25em;
          display: inline-block;
          vertical-align: text-bottom;
          font-family: "Graphik-Medium", "Roboto", "sans-serif";
          font-size: 1em;
          font-weight: 100;
          vertical-align: middle;
        }
      }
    }
  }

  .c-interim-kill-sheet-details__content {
    background: #fff;
    height: 75%;
    border-radius: 0 0 0 0.3em;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    -webkit-box-shadow: inset 0 20px 20px -10px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: inset 0 20px 20px -10px rgba(0, 0, 0, 0.16);
    box-shadow: inset 0 20px 20px -10px rgba(0, 0, 0, 0.16);

    .c-interim-kill-sheet-details__content-container {
      max-width: 45em;
      padding: 2em 0;
      height: 100%;
      overflow: hidden;
      display: table;

      .c-interim-kill-sheet__dashed-bottom-border {
        border-bottom: 1px dashed rgba(0, 0, 0, 0.16);
        margin: 0.5em 2em;
      }

      .c-interim-kill-sheet-details__message {
        font-family: "Graphik", "Roboto", "sans-serif";
        font-size: 0.9em;
        margin: 0 2em;
      }

      .c-interim-kill-sheet-details__primary-attributes-container {
        display: inline-block;
        width: 100%;
        padding: 0 2em;

        .c-interim-kill-sheet-details__primary-attribute {
          padding: 0 0.5em 0.25em 0;
          font-size: 1.3em;
          width: 37%;
          display: inline-block;

          .c-interim-kill-sheet-details__attribute-subheading {
            font-family: "Graphik", "Roboto", "sans-serif";
            font-size: 0.6em;
            color: #bababa;
          }
        }
      }

      .c-interim-kill-sheet-details__secondary-attributes-container {
        display: inline-block;
        margin: 1em 2em 0.5em 2em;
        font-family: "Graphik-Medium", "Roboto", "sans-serif";
        font-size: 1em;
        width: 100%;

        .c-interim-kill-sheet-details__secondary-attribute {
          width: 31%;
          padding: 0 0.5em 0.5em 0;
          display: inline-block;
        }

        .c-interim-kill-sheet-details__row-attribute {
          width: 100%;
          padding: 0 2.5em 0.5em 0;
          display: inline-block;
        }

        .c-interim-kill-sheet-details__attribute-subheading {
          font-family: "Graphik", "Roboto", "sans-serif";
          font-size: 0.75em;
          color: #bababa;
        }
      }

      .c-interim-kill-sheet-details__hit-rate-container {
        margin: 1.5em 2em;
      }
    }
  }
}

/* Media queries - Responsive timeline on screens less than 960px wide */
@media all and (max-width: 960px) {
  .c-interim-kill-sheet-details__content-container {
    min-width: 31em;
    .c-interim-kill-sheet-details__content {
      .c-interim-kill-sheet-details__secondary-attributes-container {
        width: 90%;
      }
    }
  }
}

/* IE Only - on screens less than 960px wide */
@media all and (max-width: 960px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .c-interim-kill-sheet-details__content-container {
    .c-interim-kill-sheet-details__content {
      .c-interim-kill-sheet-details__secondary-attributes-container,
      .c-interim-kill-sheet-details__health-report-container,
      .c-interim-kill-sheet-details__grades-container {
        width: 84% !important;
      }
      .c-interim-kill-sheet-details__hit-rate-container {
        width: 73% !important;
      }
    }
    .c-interim-kill-sheet__dashed-bottom-border {
      width: 73%;
    }
  }
}

/* Media queries - Responsive timeline on screens between 961px and 1280px wide */
@media all and (min-width: 961px) and (max-width: 1280px) {
  .c-interim-kill-sheet-details__content-container {
    min-width: 37em;
  }
}

@media all and (max-height: 768px) {
  .c-interim-kill-sheet-details__content-container {
    height: 100%;
  }
}
