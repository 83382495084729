.c-nait-item {
  &__container {
    padding: 2em;
    padding-bottom: 4em;
    margin: 1.5em 5em 2em 5em;
    background-color: white;
    border-radius: 0.3em;
    box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.16);
    min-width: 540px;
    max-width: 1080px;
  }

  &__field {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    padding: 1.5em;
    border-bottom: 1px solid rgb(239, 239, 239);
  }

  &__species {
    width: 10%;
    white-space: nowrap;
    font-family: "Graphik";
    text-align: left;
    text-transform: uppercase;
    font-size: xx-large;
    font-weight: bold;
    color: $dark-grey;
  }

  &__wrapper {
    vertical-align: middle;
  }

  &__label {
    white-space: nowrap;
    font-family: "Graphik";
    font-size: 0.9em;
    text-align: left;
    color: rgb(155, 155, 155);
  }

  &__value {
    font-family: "Graphik-Medium";
    font-size: 0.9em;
    text-align: left;
    color: $dark-grey;
    overflow-anchor: auto;
  }
}

.c-nait {
  &__empty-nait-numbers-container {
    width: 100%;
    max-width: 40em;
    min-width: 31em;
    height: 72vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__empty-nait-numbers-text {
    font-family: "Graphik";
    font-weight: 100;
    font-size: 1.1em;
    text-align: center;
    margin-top: 2em;
  }
}

@media all and (max-width: 1160px) {
  .c-nait-item {
    &__field {
      flex-direction: column;
    }
  }
}

@media all and (max-width: 1024px) {
  c-nait-item {
    &__container {
      margin: 1em 5em 2em 2em;
    }

    &__field {
      flex-direction: column;
    }
  }
}
