.c-whats-new {
  &__container {
    display: inline-block;
    width: 100%;
    height: calc(100vh - 3.25em);
    -ms-overflow-style: none;
    overflow: "-moz-scrollbars-none";
    scrollbar-width: none;
    flex-direction: row;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    background-color: #f4f4f4;

    &-tablet {
      max-width: calc(100vw - 72px);
      overflow-x: auto;
      position: relative;
    }
  }

  &__header {
    font-size: 1.5em;
    margin: 1em 0 0.5em 2em;
    text-transform: uppercase;
  }

  &__load-error-container {
    height: 85vh;
    display: flex;
    margin-left: 3em;
    width: 17em;
  }

  &__load-error-container-tablet {
    height: 85vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__empty-articles-container {
    width: 100%;
    height: 72vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__empty-articles-text {
    font-family: "Graphik";
    font-weight: 100;
    font-size: 1.1em;
    text-align: center;
    margin-top: 2em;
  }
}

.c-whats-new-primary__container {
  min-height: 100%;
  width: 480px;
  background: white;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.16);

  &-tablet {
    min-width: 710px;
    background: white;
    overflow-y: auto;
    height: calc(100vh - 3.25em);
  }
}

.c-whats-new-list {
  &__container {
    height: calc(100vh - 3.25em);
  }
  &__item-container {
    height: fit-content;
    padding: 1.25em 1.5em 1.25em 3em;
    border: 1px solid rgb(240, 240, 240);
    cursor: pointer;
  }

  &__item-container-selected {
    @extend .c-whats-new-list__item-container;
    background-color: rgba(66, 123, 93, 0.07);
  }

  &__item-container-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__item-title {
    font-family: "Graphik-Semibold";
    font-size: 1em;
  }

  &__item-date {
    font-family: "Graphik";
    font-size: 0.8em;
    color: rgb(155, 155, 155);
    white-space: nowrap;
    margin-left: 1em;
  }

  &__item-container-body {
    margin: 1em 0 1.5em 0;
  }

  &__item-body {
    font-family: "Graphik";
    font-size: 0.8em;
    color: rgb(155, 155, 155);
  }

  &__item-container-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__item-pdf-description {
    font-family: "Graphik";
    font-size: 0.8em;
    color: rgb(51, 51, 51);
  }

  &__item-view-button {
    font-family: "Graphik";
    font-size: 0.8em;
    color: rgb(66, 123, 93);
    flex-direction: row;
    display: flex;
    cursor: pointer;
  }

  &__item-view-chevron {
    margin-left: 0.5em;
  }

  &__item {
    height: fit-content;
  }
}

.c-whats-new-secondary__container {
  flex: 1;
  background-color: #f4f4f4;
  border: 1px solid #f0f0f0;
  position: relative;
  overflow-y: auto;
  height: calc(100vh - 3.25em);

  &-tablet {
    width: 100%;
    min-width: 710px;
    position: absolute;
    top: 0;
    min-height: 100%;
    background-color: #f4f4f4;
    overflow-y: auto;
    height: calc(100vh - 3.25em);

    &-hidden {
      display: none;
    }
  }
}

.c-whats-new-page {
  &__fab {
    background: #f4f4f4 !important;
    margin: 2.5em 0 0 2.5em !important;
    position: fixed !important;
    z-index: 100;
  }
  &__container {
    margin: 6em 2em 2em 4em;
    max-width: 1080px;
  }
  &__header {
    font-family: "Graphik-Semibold";
    font-size: 2.1em;
    margin-bottom: 0.2em;
  }
  &__date-and-time {
    font-family: "Graphik";
    font-size: 0.8em;
    color: rgb(155, 155, 155);
    padding-bottom: 3em;
    border-bottom: 1px solid rgb(204, 204, 204);
  }
  &__body {
    font-family: "Graphik";
    margin: 1.5em 0 3em 0;
    color: rgb(51, 51, 51);
  }
  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  &__pdf-description {
    font-family: "Graphik";
    font-size: 0.8em;
    color: rgb(51, 51, 51);
    margin-right: 2em;
  }
  &__view-button {
    font-family: "Graphik";
    font-size: 0.8em;
    color: rgb(66, 123, 93);
    flex-direction: row;
    display: flex;
    cursor: pointer;
  }
}

@media all and (max-width: 1024px) {
  .c-whats-new {
    &__header {
      font-size: 1.2em;
      margin: 1em 0 0.5em 2.5em;
    }
  }
}
